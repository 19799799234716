import React from 'react';
import styled from 'styled-components';

import { AdvantagesDetails } from '.';
import ModalContainer from './ModalContainer';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AdvantagesDetailsModal = ({
  moreDiscount,
  categoryForMore,
  description,
  resume,
  opened,
  closeModal,
}) => (
  <ModalContainer opened={opened} closeModal={closeModal}>
    <Wrapper>
      <AdvantagesDetails
        moreDiscount={moreDiscount}
        category={categoryForMore}
        details={description}
        summary={resume.split('<br />')}
      />
    </Wrapper>
  </ModalContainer>
);

export default AdvantagesDetailsModal;

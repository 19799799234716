const initialState = {
  siteSelectedRegion: null,
  userId: null,
  rule: null,
  supplierCategory: null,
  userType: '',
  selectedRegion: null,
  selectedEvent: null,
  firstLogin: null,
  balance: '',
  events: [],
  regions: [],
  userName: '',
  stage: '',
  count: '',
  date: '',
  eventFinished: null,
  category: null,
  token: null,
  isSupplier: null,
  isBride: null,
  isFranchise: null,

  vbs: null,
  operator: null,
  potential: null,
  contractsCount: null,
  totalContractsValue: null,
  roi: null,
  pointsBalance: null,
  supplierContracts: [],
  currentSupplierContract: null,
};

export default initialState;

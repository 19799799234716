import React from 'react';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
// import { toast } from 'react-toastify';
import moment from 'moment';

import theme from '../styles/theme';
import { useStateValue } from '../state';
import { MaterialSelect, MaterialMenuItem } from './';
import { bride } from '../services/api';

const Wrapper = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 2.5em 2em 2em 2em;
`;

const SelectGroup = styled(FormControl)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledText = styled.p`
  color: ${props =>
    props.primaryColor ? theme.colors.primary : theme.colors.secondary};
  font-size: ${props =>
    props.title ? theme.fontSize * 0.9 : theme.fontSize}px;
  font-weight: bold;
  margin-bottom: 12px;
  margin-left: 3px;
`;

export const DesktopMenuSelects = ({ updateSegments }) => {
  const [
    {
      events: { events },
      session: { selectedEvent },
    },
    dispatch,
  ] = useStateValue();

  const fetchRegions = async value => {
    const response = await bride.eventRegions(value);
    if (response.ok && response.data) {
      dispatch({
        type: 'setEventRegions',
        newEventRegions: response.data,
      });
      dispatch({
        type: 'changeSessionForm',
        field: 'selectedRegion',
        newText: response.data[0].codRegiao.codRegiao,
      });
    }
  };

  const handleChangeEvent = value => {
    dispatch({
      type: 'changeSessionForm',
      field: 'selectedEvent',
      newText: value,
    });

    fetchRegions(value);
    updateState(value);
    updateSegments(value);
  };

  const updateState = async value => {
    const response = await bride.getEventData(value);

    if (response.ok && response.data) {
      dispatch({
        type: 'updateEventData',
        eventType: response.data.eventType,
        date: response.data.date,
        eventFinished:
          response.data.date < moment(new Date()).format('YYYY-MM-DD'),
        points: response.data.points,
      });
    }
  };

  let eventOptions;
  if (events) {
    eventOptions = events.map(({ evento: { codEvento, nomeEvento } }) => (
      <MaterialMenuItem value={codEvento} key={codEvento}>
        {nomeEvento}
      </MaterialMenuItem>
    ));
  }

  return (
    <Wrapper>
      <SelectGroup>
        <StyledText primaryColor>Escolha seu evento:</StyledText>
        <MaterialSelect
          value={selectedEvent || ''}
          onChange={e => handleChangeEvent(e.target.value)}
        >
          {events && eventOptions}
        </MaterialSelect>
      </SelectGroup>
    </Wrapper>
  );
};

export default DesktopMenuSelects;

import React from 'react';
import Select from '@material-ui/core/Select';
import classnames from 'classnames';

import { theme } from '../styles/theme';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const StyledSelect =styled(props => <Select {...props} />)`
width: 100% !important;
`;

const styles = {
  cssLabelWhite: {
    fontSize: theme.fontSizeSm,
    '&$cssFocused': {
      fontSize: theme.fontSizeSm,
    },
    color: theme.colors.white,
  },
  cssLabel: {
    fontSize: theme.fontSizeSm,
    '&$cssFocused': {
      fontSize: theme.fontSizeSm,
    },
  },
  cssInput: {
    fontSize: theme.fontSizeSm,
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: theme.colors.grey3,
    },
  },
};

const MaterialSelect = props => {
  const { classes, white, ...other } = props;

  return (
    <StyledSelect
      {...other}
      margin="dense"
      InputProps={{
        classes: {
          root: classes.cssInput,
          focused: classes.cssFocused,
          underline: classes.cssUnderline,
        },
      }}
      className={classnames(white ? classes.cssLabelWhite : classes.cssLabel, classes.cssUnderline)}
      placeholder="Selecione"
    />
  );
};

// All material textfield props
MaterialSelect.propTypes = {
};

export default withStyles(styles)(MaterialSelect);

import React, { useState, useRef } from 'react';
import styled from 'styled-components';

import { useStateValue } from '../state';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { TrashIcon, GreenCircle, RedCircle } from './icons';
import {
  MaterialInput,
  Button,
  ConfirmModal,
  UpdateUserModal,
} from '../components';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import { supplier } from '../services/api';
import { toast } from 'react-toastify';
import { scrollToRef } from '../hooks';
import Grid from '@material-ui/core/Grid';

const Title = styled.h2`
  color: ${theme.colors.grey3};
`;

const RowContainer = styled.div`
  @media ${medias.phoneMax} {
    align-items: baseline;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 1em 0 0.5em 0;
`;

const Text = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.9em;
  }

  font-size: ${theme.fontSize}px;
  color: ${theme.colors.grey3};
  margin: 0;
`;

const Span = styled.span`
  @media ${medias.phoneMax} {
    font-size: 0.9em;
  }

  font-size: ${theme.fontSize}px;
  color: ${theme.colors.grey3};
  margin: 0;
`;

const ClickableSpan = styled(Span)`
  margin-left: 10px;
  text-decoration: underline;
  cursor: pointer;
`;

const ClickableSpanMobile = styled(ClickableSpan)`
  display: none;
  @media (max-width: 767px) {
    display: initial;
  }
`;

const StyledTrashIcon = styled(TrashIcon)`
  cursor: pointer;
`;

const StyledGridColumn = styled(Grid)`
  text-align: center;
  line-height: 38px;
  @media (max-width: 767px) {
    display: none;
  }
`;

const StyledIconSpanMobile = styled.span`
  display: none;
  @media (max-width: 767px) {
    display: initial;
  }
`;

const FormTitle = styled.h5`
  color: ${theme.colors.text_heading};
`;

const Line = styled.div`
  height: 1px;
  width: 90%;
  background-color: ${theme.colors.grey1};
`;

const HalfButton = styled(props => <Button {...props} />)`
  @media ${medias.phoneMax} {
    height: 40px;
    font-size: ${theme.fontSizeSm}px;
  }
  background-color: ${props =>
    props.selected ? theme.colors.white : theme.colors.text_heading};
  color: ${props =>
    props.selected ? theme.colors.text_heading : theme.colors.white};
  border-color: ${theme.colors.text_heading};
  width: 90%;
  font-size: ${theme.fontSizeSm}px;
  text-align: center;
  margin-top: 1em;
`;

const GridContainer = styled(Grid)`
  border-bottom: '1px solid #afa7a7';
  line-height: '32px';
  margin-right: '0';
  margin-left: '0';
  margin-bottom: '10px';
`;

const GridColumn = styled(Grid)`
  text-align: center;
  line-height: 32px;
`;

const StyledDiv = styled.div`
  width: '100%';
  padding-left: '35px';
  padding-right: '35px';
`;

const SupplierCreateUsers = () => {
  const [form, setForm] = useState({
    codUser: null,
    name: null,
    email: null,
    password: null,
  });
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState('ADICIONAR');
  const [modalOpened, setModalOpened] = useState(false);
  const [updateModalOpened, setUpdateModalOpened] = useState(false);
  const [userToUpdate, setUserToUpdate] = useState(null);

  const [
    {
      session: { rule, userId },
    },
  ] = useStateValue();

  const addUserDiv = useRef(null);
  const executeScrollToaddUserDiv = () => scrollToRef(addUserDiv);

  useFetch({
    url: urls.supplierContent.supplierGuests,
    method: method.GET,
    condition: () => !users,
    callback: data => setUsers(data),
  });

  const updateField = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateUser = (codUser, name, email) => {
    const user = {
      codUser,
      name,
      email,
    };
    setUserToUpdate(user);
    setUpdateModalOpened(true);
  };

  const handleUserButton = () => {
    if (mode === 'ALTERAR') {
      setModalOpened(true);
      return;
    }

    addUser();
  };

  const updateUser = async userUpdatedForm => {
    setLoading(true);
    const { codUser, ...payload } = userUpdatedForm;
    const response = await supplier.alterGuest({ codUser, ...payload });
    if (response.ok && response.data) {
      const alteredState = users.map(user => {
        if (codUser === user.codUser) {
          user = {
            ...user,
            name: payload.name,
            email: payload.email,
          };
        }
        return user;
      });
      setUsers(alteredState);
      toast.success('Usuário atualizado com sucesso');
    } else {
      toast.error('Erro ao alterar usuário');
    }
    changeUser(null, '', '', '');
    setMode('');
    setLoading(false);
  };

  const addUser = async () => {
    setLoading(true);
    const { ...payload } = form;
    const response = await supplier.addGuest(payload);
    if (response.ok && response.data) {
      const newState = [...users, response.data];
      setUsers(newState);
      toast.success('Usuário Adicionado com sucesso');
    } else {
      const toastMessage =
        response.status === 409
          ? 'Email já cadastrado no sistema.'
          : 'Error ao adicionar usuário';
      toast.error(toastMessage);
    }
    changeUser(null, '', '', '');
    setMode('');
    setLoading(false);
  };

  const deleteUser = async cod => {
    const oldState = users;
    const newState = users.filter(({ codUser }) => codUser !== cod);
    setUsers(newState);
    const response = await supplier.deleteGuest(cod);
    if (!response.ok) {
      toast.error('Erro ao deletar');
      setUsers(oldState);
    }
  };

  const changeUser = (cod, name, email, password) => {
    setForm({
      ...form,
      codUser: cod,
      name: name,
      email: email,
      password: password || '',
    });
    setMode('ALTERAR');
    executeScrollToaddUserDiv();
  };

  return (
    <>
      <ConfirmModal
        opened={modalOpened}
        closeModal={() => setModalOpened(false)}
        onClickConfirm={() => addUser()}
        text={'Você tem certeza que quer alterar esse usuário?'}
      />
      {updateModalOpened && (
        <UpdateUserModal
          opened={updateModalOpened}
          closeModal={() => setUpdateModalOpened(false)}
          onClickConfirm={userUpdatedForm => updateUser(userUpdatedForm)}
          rule={rule}
          userToUpdate={userToUpdate}
          codUserLoggedIn={userId}
        />
      )}
      <Title>Cadastro de Usuários</Title>
      <FormTitle>Usuários</FormTitle>
      <StyledDiv>
        {users ? (
          users.length > 0 ? (
            users.map(
              ({
                codUser,
                name,
                email,
                inativo,
                codRegraUsuarioFornecedor,
              }) => (
                <GridContainer container spacing={8} key={codUser}>
                  <GridColumn item xs={12} sm={12} md={2}>
                    <Text>{name}</Text>
                  </GridColumn>

                  <GridColumn item xs={12} sm={12} md={5}>
                    <Text>{name}</Text>
                  </GridColumn>

                  <GridColumn item xs={12} sm={12} md={2}>
                    <Text>
                      <StyledIconSpanMobile className="col-md-1">
                        {inativo === 0 && (
                          <span title="Ativo">
                            {' '}
                            <GreenCircle />
                          </span>
                        )}
                        {inativo === 1 && (
                          <span title="Inativo">
                            <RedCircle />
                          </span>
                        )}
                      </StyledIconSpanMobile>
                      {codRegraUsuarioFornecedor.codRegraUsuarioFornecedor === 1
                        ? 'Adm'
                        : 'Usuário'}
                    </Text>
                  </GridColumn>

                  <GridColumn
                    item
                    xs={12}
                    sm={12}
                    md={Number(rule) === 2 ? 2 : 1}
                  >
                    <ClickableSpan
                      onClick={() => handleUpdateUser(codUser, name, email)}
                    >
                      Alterar
                    </ClickableSpan>

                    <ClickableSpanMobile onClick={() => deleteUser(codUser)}>
                      Deletar
                    </ClickableSpanMobile>
                  </GridColumn>

                  <StyledGridColumn item xs={12} sm={12} md={1}>
                    {inativo === 0 && (
                      <div title="Ativo">
                        {' '}
                        <GreenCircle />
                      </div>
                    )}
                    {inativo === 1 && (
                      <div title="Inativo">
                        <RedCircle />
                      </div>
                    )}
                  </StyledGridColumn>

                  {Number(rule) === 1 && (
                    <StyledGridColumn item xs={12} sm={12} md={1}>
                      <StyledTrashIcon onClick={() => deleteUser(codUser)} />
                    </StyledGridColumn>
                  )}
                </GridContainer>
              )
            )
          ) : (
            <p>Nenhum usuário</p>
          )
        ) : (
          <p>Nenhum usuário</p>
        )}
      </StyledDiv>
      <Line />
      <FormTitle>Adicionar Usuário</FormTitle>
      <MaterialInput
        style={{ width: '90%' }}
        label="Nome"
        name="name"
        value={form.name}
        onChange={updateField}
      />
      <RowContainer>
        <MaterialInput
          style={{ width: '50%', marginRight: '20px' }}
          label="E-mail"
          name="email"
          value={form.email}
          onChange={updateField}
        />

        <MaterialInput
          style={{ width: '50%' }}
          label="Senha"
          name="password"
          value={form.password}
          onChange={updateField}
        />
      </RowContainer>
      {!loading && (
        <HalfButton text="ADICIONAR" click={() => handleUserButton()} />
      )}
    </>
  );
};

export default SupplierCreateUsers;

export { default as BrideForm } from './BrideForm';
export { default as ContactUsForm } from './ContactUsForm';
export { default as FranchiseForm } from './FranchiseForm';
export { default as PasswordModal } from './PasswordModal';
export { default as SuppliersForm } from './SuppliersForm';
export { default as Slider } from './Slider';
export { default as LoginModal } from './LoginModal';
export { default as PortfolioCard } from './PortfolioCard/PortfolioCard';
export { default as SupplierAdvantages } from './SupplierAdvantages/SupplierAdvantages';
export { default as BrideReferForm } from './BrideReferForm';
export { default as BrideProfileForm } from './BrideProfileForm';


/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import styled from 'styled-components';

import Arabesco from '../images/arabescos.svg';
import { medias } from '../utils/mediaQueries';
import theme from '../styles/theme';
import { hexToRgb } from '../utils/hexToRgb';
import FormControl from '@material-ui/core/FormControl';
import {
  Button,
  MaterialInputLabel,
  MaterialInput,
  MaterialSelect,
  MaterialMenuItem,
  MoneyMask,
} from './';
import Loader from './Loader';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
  width: 100%;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const AssetContainer = styled.div`
  @media ${medias.tabletMin} {
    background-size: contain;
  }

  background-image: url(${Arabesco});
  background-size: cover;
  background-repeat-x: repeat;
  width: 100%;
  height: 2em;
  ${({ bottom }) =>
    bottom
      ? 'margin-top: 1em; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; '
      : 'margin-bottom: 1em;'}
`;

const HalfButton = styled(props => <Button {...props} />)`
  @media ${medias.phoneMax} {
    height: 40px;
    font-size: ${theme.fontSizeSm}px;
  }
  background-color: ${props =>
    props.selected ? theme.colors.white : theme.colors.text_heading};
  color: ${props =>
    props.selected ? theme.colors.text_heading : theme.colors.white};
  border-color: ${theme.colors.text_heading};
  width: 90%;
  font-size: ${theme.fontSizeSm}px;
  text-align: center;
  margin-top: 1em;
`;

const Row = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
`;

const StyledFormControl = styled(props => <FormControl {...props} />)`
  width: ${({ half }) => (half ? '45%' : '100%')};
`;

const FileInput = styled.div`
  --border-color: rgba(0, 0, 0, 0.42);
  width: 45%;
  position: relative;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  label {
    padding: 0 0.5rem 0 0;
    flex: 1;
    div {
      font-size: 0.8em;
      margin-top: 0.5em;
      color: ${theme.colors.grey3};
    }
  }
  button {
    border-left: 1px solid var(--border-color);
    padding: 0.5rem;
  }
  svg {
    width: 1em;
  }
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
`;

const CreateAdvantagesForm = forwardRef(
  (
    {
      addAdvantage,
      regions,
      segments,
      form,
      setForm,
      loading,
      imageName,
      setImageName,
    },
    ref
  ) => {
    const getBase64 = file => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    };

    const imageUpload = e => {
      setImageName(undefined);
      const { files } = e.currentTarget;
      if (!files || files.length <= 0) {
        return;
      }
      setImageName(files[0].name);
      getBase64(files[0]).then(data =>
        setForm({
          ...form,
          file: data,
        })
      );
    };

    const updateField = e => {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    };

    const updateMoneyInput = input => {
      setForm({
        ...form,
        discountValue: input,
      });
    };

    return (
      <Wrapper ref={ref}>
        <AssetContainer />
        <MaterialInput
          style={{ width: '90%' }}
          name="name"
          label="Nome do benefício*"
          value={form.name}
          onChange={updateField}
        />
        <Row>
          <StyledFormControl half>
            <MaterialInputLabel htmlFor="codSegment">
              Segmento*
            </MaterialInputLabel>
            <MaterialSelect
              style={{ marginTop: '21px' }}
              name="codSegment"
              onChange={updateField}
              value={form.codSegment}
              inputProps={{
                name: 'codSegment',
                id: 'codSegment',
              }}
            >
              <MaterialMenuItem value={0}>Selecione</MaterialMenuItem>
              {segments
                ? segments.map(({ codSegment, segment }) => (
                    <MaterialMenuItem key={codSegment} value={codSegment}>
                      {segment}
                    </MaterialMenuItem>
                  ))
                : null}
            </MaterialSelect>
          </StyledFormControl>
          <MaterialInput
            style={{ width: '45%' }}
            name="discountValue"
            label="Valor do desconto VIP*"
            value={form.discountValue}
            onChange={e => updateMoneyInput(e.target.value)}
            InputProps={{
              inputComponent: MoneyMask,
            }}
          />
        </Row>
        <Row>
          <StyledFormControl half>
            <MaterialInputLabel htmlFor="codRegion">Região*</MaterialInputLabel>
            <MaterialSelect
              style={{ marginTop: '21px' }}
              name="codRegion"
              onChange={updateField}
              value={form.codRegion}
              inputProps={{
                name: 'codRegion',
                id: 'codRegion',
              }}
            >
              <MaterialMenuItem value={0}>Selecione</MaterialMenuItem>
              {regions
                ? regions.map(({ codRegion, region }) => (
                    <MaterialMenuItem key={codRegion} value={codRegion}>
                      {region}
                    </MaterialMenuItem>
                  ))
                : null}
            </MaterialSelect>
          </StyledFormControl>
          <FileInput>
            <MaterialInputLabel htmlFor="imageUpload">
              {typeof imageName === 'string' ? 'Trocar' : 'Carregar'} imagem*
              {imageName && <div>{imageName}</div>}
            </MaterialInputLabel>
            <button aria-title="Botão para enviar uma imagem">
              {/* SVG de upload */}
              <svg
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
              >
                <path d="M11.492 10.172l-2.5 3.064-.737-.677 3.737-4.559 3.753 4.585-.753.665-2.5-3.076v7.826h-1v-7.828zm7.008 9.828h-13c-2.481 0-4.5-2.018-4.5-4.5 0-2.178 1.555-4.038 3.698-4.424l.779-.14.043-.789c.185-3.448 3.031-6.147 6.48-6.147 3.449 0 6.295 2.699 6.478 6.147l.044.789.78.14c2.142.386 3.698 2.246 3.698 4.424 0 2.482-2.019 4.5-4.5 4.5m.978-9.908c-.212-3.951-3.472-7.092-7.478-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.522-5.408" />
              </svg>
            </button>
            <input
              type="file"
              name="imageUpload"
              id="imageUpload"
              onChange={imageUpload}
              accept="image/*"
            />
          </FileInput>
        </Row>
        <MaterialInput
          label="Descrição*"
          style={{ width: '90%' }}
          name="description"
          multiline
          rows="3"
          value={form.description}
          onChange={updateField}
        />
        <MaterialInput
          label="Regulamento"
          style={{ width: '90%' }}
          name="regulation"
          multiline
          rows="3"
          value={form.regulation}
          onChange={updateField}
        />
        {loading ? (
          <Loader />
        ) : (
          <HalfButton text="CADASTRAR / ALTERAR" click={addAdvantage} />
        )}

        <AssetContainer bottom />
      </Wrapper>
    );
  }
);

export default CreateAdvantagesForm;

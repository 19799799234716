import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';

import Arabesco from '../images/arabescos.svg';
import theme from '../styles/theme';
import { Button } from '../components';
import { StyledHeart, StyledOutline } from './styled';
import { hexToRgb } from '../utils/hexToRgb';
import { medias } from '../utils/mediaQueries';
import { useStateValue } from '../state';
import { bride, tracking } from '../services/api';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  margin-bottom: 20px;
  width: 100%;
  min-height: 500px;
  max-height: 500px;
  height: 500px;

  background-color: ${theme.colors.white};
  border-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const AssetContainer = styled.div`
  background-image: url(${Arabesco});
  background-size: cover;
  width: 100%;
  height: 40px;
  min-height: 40px;
  border-radius: 10px;
  ${({ bottom }) => bottom && 'margin-top: 20px;'}
  cursor: pointer;
`;

const IconsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  width: 75%;
  max-height: 40px;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const SegmentsContainer = styled.div`
  cursor: pointer;
`;

const Title = styled.h3`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSize}px;
  font-weight: bold;
  text-align: center;
  width: 90%;
  line-height: 2.5ex;
  height: 7.5ex;
  cursor: pointer;
`;

const StyledIcon = styled.img`
  width: 30px;
  height: 30px;
  fill: ${({ color }) => color};
`;

const StyledButton = styled(props => <Button {...props} />)`
  @media ${medias.phoneMax} {
    font-size: ${theme.fontSizeSm}px;
    height: 41px;
    padding: 0 20px;
  }

  font-size: ${theme.fontSizeSm}px;
  background-color: ${theme.colors.text_heading};
  color: ${theme.colors.white};
  border-color: ${theme.colors.text_heading};
  width: 90%;
  margin: 0 20px;
`;

const RegionContainer = styled.span`
  text-align: center;
  cursor: pointer;
`;

const HeartsContainer = styled.div`
  display: flex;
`;

const Image = styled.img`
  height: 20%;
  object-fit: contain;
  cursor: pointer;
`;

const SmallSupplierCard = ({
  supplierData,
  app,
  removeFavorite,
  addFavorite,
  onClickRequest,
  isFavoritePage,
}) => {
  const [favorited, setFavorited] = useState(supplierData.userFavorited);

  const [
    {
      session: { userId, selectedEvent },
    },
  ] = useStateValue();

  useEffect(() => {
    const getFavorite = async () => {
      const response = await bride.getFavoriteEvent(
        supplierData.codFornecedor,
        selectedEvent
      );

      setFavorited(response.data.isFavorite);
    };

    if (app) {
      getFavorite();
    }
  }, [app, selectedEvent, supplierData.codFornecedor]);

  const renderHeart = () => (
    <>
      {favorited ? (
        <StyledHeart
          onClick={() => {
            setFavorited(!favorited);
            removeFavorite(codFornecedor, 'supplier');
          }}
        />
      ) : (
        <StyledOutline
          onClick={() => {
            setFavorited(!favorited);
            addFavorite(codFornecedor, 'supplier');
          }}
        />
      )}
    </>
  );

  const renderOtherBrideHeart = () =>
    supplierData.otherBrideFavorited ? (
      <StyledHeart secondary />
    ) : (
      <StyledOutline secondary />
    );

  const {
    nomeDivulgacao,
    codFornecedor,
    segmentos,
    Regiao,
    linkLogo,
  } = supplierData;

  const handleNavigate = () => {
    const route = nomeDivulgacao
      .toLowerCase()
      .replace(new RegExp(',', 'g'), '')
      .replace(new RegExp('-', 'g'), '')
      .replace(new RegExp(' ', 'g'), '-');

    tracking.supplier({
      userId,
      eventId: selectedEvent,
      supplierId: codFornecedor,
    });

    if (app) {
      return navigate(`fornecedor/${route}`, { state: { id: codFornecedor } });
    }

    return navigate(`/${route}`);
  };

  return (
    <Wrapper>
      <AssetContainer onClick={handleNavigate} />
      <Image src={linkLogo} onClick={handleNavigate} />
      <IconsContainer>
        <SegmentsContainer onClick={handleNavigate}>
          {segmentos.map(item => (
            <StyledIcon key={item.codSegmento} src={item.linkIcone} />
          ))}
        </SegmentsContainer>
        <HeartsContainer>
          {app && renderHeart()}
          {isFavoritePage && renderOtherBrideHeart()}
        </HeartsContainer>
      </IconsContainer>
      {!app && (
        <RegionContainer onClick={handleNavigate}>{Regiao}</RegionContainer>
      )}
      <Title onClick={handleNavigate}>{nomeDivulgacao}</Title>
      <StyledButton
        text="SOLICITAR ORÇAMENTO"
        click={() => onClickRequest(supplierData)}
      />
      <AssetContainer onClick={handleNavigate} bottom />
    </Wrapper>
  );
};

export default SmallSupplierCard;

import React from 'react';
import styled from 'styled-components';

import theme from '../styles/theme';
import { hexToRgb } from '../utils/hexToRgb';
import { SegmentListAccordion } from '../components';
import { AssetContainer } from '../components/styled';

const LimitedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${theme.colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

export const Checklist = ({ suppliers, segments, mode, updatePage }) => {
  if (!segments) {
    return null;
  }
  let _segments;

  switch (mode) {
    case 'all':
      _segments = segments;
      break;
    case 'vdb':
      _segments = segments.filter(({ vdb }) => vdb === 1);
      break;
    case 'outside':
      _segments = segments.filter(({ vdb }) => vdb === 0);
      break;
    default:
      _segments = segments;
  }

  const cardData = _segments.map(
    ({
      codCheckList,
      codSegmento,
      finalizado,
      prioritario,
      Fornecedor,
      valorFechado,
      valorOrcado,
      codEvento,
      codContratoEvento,
      vdb,
      supplierIndicated,
    }) => ({
      id: codCheckList,
      segment: codSegmento,
      finished: finalizado,
      favorite: prioritario,
      supplier: Fornecedor,
      finalValue: valorFechado,
      budget: valorOrcado,
      event: codEvento,
      contract: codContratoEvento,
      vdb: vdb,
      supplierIndicated,
    })
  );

  const initialSegments = cardData.filter(
    item => item.segment.faseSegmento.codFaseSegmento === 1
  );
  initialSegments.sort((a, b) => {
    const textA = a.segment.Segmento.toUpperCase();
    const textB = b.segment.Segmento.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
  const mediumSegments = cardData.filter(
    item => item.segment.faseSegmento.codFaseSegmento === 2
  );
  mediumSegments.sort((a, b) => {
    const textA = a.segment.Segmento.toUpperCase();
    const textB = b.segment.Segmento.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
  const finalSegments = cardData.filter(
    item => item.segment.faseSegmento.codFaseSegmento === 3
  );
  finalSegments.sort((a, b) => {
    const textA = a.segment.Segmento.toUpperCase();
    const textB = b.segment.Segmento.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  return (
    <LimitedWrapper>
      <AssetContainer />
      <SegmentListAccordion
        title="Fase Inicial"
        items={initialSegments}
        include
        wrapperSize={'90%'}
        suppliers={suppliers}
        updatePage={updatePage}
      />
      <SegmentListAccordion
        title="Fase Intermediária"
        items={mediumSegments}
        include
        wrapperSize={'90%'}
        suppliers={suppliers}
        updatePage={updatePage}
      />
      <SegmentListAccordion
        title="Fase Final"
        items={finalSegments}
        include
        wrapperSize={'90%'}
        suppliers={suppliers}
        updatePage={updatePage}
      />
      {/* {
        (cardData && cardData.length > 0)
          ? cardData.map(checklistItem => (
            <ChecklistCard
              key={checklistItem.id}
              data={checklistItem}
              suppliers={suppliers}
              updatePage={updatePage}
            />
          ))
          : <p>Nenhum segmento</p>
      } */}
    </LimitedWrapper>
  );
};

export default Checklist;

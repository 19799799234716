// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-external-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/components/External.jsx" /* webpackChunkName: "component---src-components-external-jsx" */),
  "component---src-site-parceiro-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/site/parceiro.jsx" /* webpackChunkName: "component---src-site-parceiro-jsx" */),
  "component---src-site-contato-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/site/contato.jsx" /* webpackChunkName: "component---src-site-contato-jsx" */),
  "component---src-site-facaparte-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/site/facaparte.jsx" /* webpackChunkName: "component---src-site-facaparte-jsx" */),
  "component---src-site-facaparte-fornecedor-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/site/facaparte/fornecedor.jsx" /* webpackChunkName: "component---src-site-facaparte-fornecedor-jsx" */),
  "component---src-site-facaparte-franqueado-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/site/facaparte/franqueado.jsx" /* webpackChunkName: "component---src-site-facaparte-franqueado-jsx" */),
  "component---src-site-facaparte-noivo-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/site/facaparte/noivo.jsx" /* webpackChunkName: "component---src-site-facaparte-noivo-jsx" */),
  "component---src-site-faq-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/site/faq.jsx" /* webpackChunkName: "component---src-site-faq-jsx" */),
  "component---src-site-fornecedores-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/site/fornecedores.jsx" /* webpackChunkName: "component---src-site-fornecedores-jsx" */),
  "component---src-site-index-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/site/index.jsx" /* webpackChunkName: "component---src-site-index-jsx" */),
  "component---src-site-sobre-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/site/sobre.jsx" /* webpackChunkName: "component---src-site-sobre-jsx" */),
  "component---src-app-app-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/app/app.jsx" /* webpackChunkName: "component---src-app-app-jsx" */)
}


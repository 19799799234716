import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';

import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';


import {
  VdbIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  YoutubeIcon,
} from './icons';

const StyledContainer = styled.div`
  /* display: ${props => !props.isFirstAccess ? 'none' : 'flex'}; */
  display: flex;
  height: 275px;
  flex: 1;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: ${theme.colors.secondary};
`;

const StyledSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 10px;

  a {
    text-decoration: none;
  }
`;

const StyledLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledConnectText = styled.h3`
  @media ${medias.phoneMax} {
    font-size: 1em;
  }

  font-size: ${theme.fontSize * 1.12};
  font-weight: normal;
  color: ${theme.colors.primary};
  margin-bottom: 15px;
`;

const StyledBlogTitle = styled.h4`
  @media ${medias.phoneMax} {
    font-size: 0.85em;
  }

  color: ${theme.colors.primary};
  margin-bottom: 15px;
`;

const StyledLink = styled(Link)`
  @media ${medias.phoneMax} {
    font-size: 0.85em;
  }

  color: ${theme.colors.white};
  margin-bottom: 10px;
  text-decoration: none;

  cursor: pointer;

  &:hover {
    color: ${theme.colors.primary};
  }
`;

const StyledLine = styled.div`
  @media ${medias.phoneMax} {
    width: 85%;
  }

  width: 100%;
  height: 1px;
  background-color: ${theme.colors.grey2};
  margin: 20px 0 15px 0;
`;

const StyledFbIcon = styled(FacebookIcon)`
  margin-right: 15px;

  &:hover {
    cursor: pointer;
    fill: ${theme.colors.primary};
  }
`;

const StyledInstaIcon = styled(InstagramIcon)`
  margin-right: 15px;

  &:hover {
    cursor: pointer;
    fill: ${theme.colors.primary};
  }
`;

const StyledLinkedinIcon = styled(LinkedinIcon)`
  margin-right: 15px;

  &:hover {
    cursor: pointer;
    fill: ${theme.colors.primary};
  }
`;

const StyledYoutubeIcon = styled(YoutubeIcon)`
  &:hover {
    cursor: pointer;
    fill: ${theme.colors.primary};
  }
`;

const Footer = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          version
        }
      }
    }
  `);

  return (
    <StyledContainer>
      <StyledSubContainer>
        <VdbIcon />
        <StyledLine />
        <StyledConnectText>CONECTE-SE CONOSCO</StyledConnectText>
        <StyledLogoContainer>
          <a
            href="https://facebook.com/vestidasdebranco"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledFbIcon />
          </a>
          <a
            href="https://instagram.com/vestidasdebranco"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledInstaIcon />
          </a>
          <a
            href="https://www.linkedin.com/company/vestidasdebranco"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledLinkedinIcon />
          </a>
          <a
            href="https://youtube.com/vestidasdebranco"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledYoutubeIcon />
          </a>
        </StyledLogoContainer>
        v0.{site.siteMetadata.version}
      </StyledSubContainer>
      <StyledSubContainer>
        <a
          href="https://www.blog.vestidasdebranco.com.br"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledBlogTitle>Blog</StyledBlogTitle>
        </a>
        <StyledLink to="/fornecedores">Fornecedores Certificados</StyledLink>
        <StyledLink to="/facaparte">Faça Parte</StyledLink>
        <StyledLink to="/sobre">Quem Somos</StyledLink>
        <StyledLink to="/faq">Perguntas Frequentes</StyledLink>
        <StyledLink to="/contato">Fale Conosco / Ouvidoria</StyledLink>
      </StyledSubContainer>
    </StyledContainer>
  );
};

export default Footer;

import React, {useState, useEffect} from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import {
  HeaderLogo,
  HeaderWrapper,
  IconLink,
  TopHeaderContainer,
} from './HeaderComponents';
import { ChainIcon } from '../icons';
import Button from '../Button';
import { medias } from '../../utils/mediaQueries';
import NativeSelect from '@material-ui/core/NativeSelect';
import { siteContent } from '../../services/api';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { checkSiteSelectedRegion } from '../../services/checkSession';
import Grid from '@material-ui/core/Grid';

const StyledButton = styled(Button)`
  display: inline-block;
  margin-left: 0.5em;
  /*
    Diminuimos o tamanho do botão no celular pra sobrar espaço pra tudo sem precisar pular linha 
  */
  @media ${medias.phoneMax} {
    font-size: 0.75em;
  }
`;

const StyledNativeSelect =  styled(NativeSelect)`
  font-size:14px;
`;

const StyledGridItem = styled(Grid)`
  text-align: right;
  @media ${medias.smallerTabletMax} {
    text-align: center;
  }
`;

const LogoGrid = styled(Grid)`
  @media ${medias.smallerTabletMax} {
    text-align: center;
  }
`;

const PublicHeader = ({ dispatch, visibility, siteRegion = '' }) => {
  const [regionValue, setRegionValue] = useState(siteRegion);
  const [regions, setRegions] = useState(null);

  const handleChange = (value) => {
    setRegionValue(value);
    sessionStorage.setItem('siteSelectedRegion', value);
    checkSiteSelectedRegion(dispatch);
    window.location.reload();
  };

  useEffect(() => {
    const fetchRegions = async () => {
      const response = await siteContent.regions();
      if (response.ok && response.data) {
        setRegions(response.data);
      }
    };
    fetchRegions();
  }, []);

  return (
    <HeaderWrapper>
      <TopHeaderContainer>
        <Grid container spacing={8}>
          <LogoGrid item xs={12}  sm={4} md={4}>
            <HeaderLogo path="/" isPublicSite/>
          </LogoGrid>
          <StyledGridItem item xs={12} sm={4} md={5}>
            <FormControl >
              <InputLabel shrink>Região</InputLabel>
              <StyledNativeSelect
                value={regionValue}
                onChange={(event) => handleChange(event.target.value)}
              >
                { regions && regions.map(item => (
                  <option key={item.codRegiao} value={item.Regiao}>{item.Regiao}</option>
                ))}
              </StyledNativeSelect>
            </FormControl>
          </StyledGridItem>
         
          <StyledGridItem item xs={12} sm={4} md={3}>
            <IconLink icon={ChainIcon}>
              <Link to="/facaparte">Faça Parte</Link>
            </IconLink>
            <StyledButton
              click={() =>
                dispatch({
                  type: 'toggleLoginModal',
                  newVisibility: !visibility.loginModal,
                })
              }
              text="LOGIN"
            />
          </StyledGridItem>
         
        </Grid>
      </TopHeaderContainer>
    </HeaderWrapper>
  );
};

export default PublicHeader;

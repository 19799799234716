import React from 'react';
import styled from 'styled-components';
// import PropTypes from 'prop-types';

import theme from '../styles/theme';
// import { medias } from '../utils/mediaQueries';
import { CardContainer, PrizeReports } from './';

//@TODO Responsive CSS when implementing full page
//@TODO PropTypes when implementing full page
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ last }) =>
    !last &&
    `border-bottom: 1px solid ${theme.colors.grey2}; margin-bottom: 1em`};
  padding: 0 0 1em 0;
`;

const DataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    color: ${theme.colors.text_heading};
    align-self: flex-start;
    margin: 0;
  }

  p {
    color: ${theme.colors.grey3};
    font-size: 0.8em;
    margin: 0;

    strong {
      color: ${theme.colors.primary};
    }
  }
`;

const SupplierStockedPrizesCard = ({ data, reportData }) => {
  const { redeemed, stock } = reportData;
  return (
    <Wrapper>
      <CardContainer>
        {
          data.length > 0 ? 
          data.map(({ id, title, stock }, index) => (
          <ItemContainer key={id} last={index === data.length - 1}>
            <DataContainer>
              <h4>{title}</h4>
              <p>
                Estoque: <strong>{stock}</strong>
              </p>
            </DataContainer>
          </ItemContainer>
        )) : <p style={{textAlign: 'center'}}>Nenhum prêmio em estoque.</p>
        }
        <PrizeReports redeemed={redeemed} stock={stock} />
      </CardContainer>
    </Wrapper>
  );
};

export default SupplierStockedPrizesCard;

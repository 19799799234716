import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { theme } from '../styles/theme';
import { medias } from '../utils/mediaQueries';

const Wrapper = styled.div`
  width: 90%;
  margin-top:1em;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Tab = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  cursor: pointer;
`;

const TabLine = styled.div`
  display: flex;
  align-self: flex-end;
  width: 100%;
  background-color: ${theme.colors.primary};
  height: 3px;
`;

const TabGreyLine = styled.div`
  background-color: ${theme.colors.grey2};
  height: 2px;
  margin: 1px 0 1px 0;
  width: ${props => props.width || '100%'};
`;

const TabTitle = styled.h3`
  @media ${medias.phoneMax} {
    font-size: 0.6em;
  }

  display: flex;
  align-self: center;
  color: ${props =>
    props.selected ? theme.colors.primary : theme.colors.grey3};
  font-weight: ${props => (props.selected ? 'bold' : 'normal')};
  font-size: ${theme.fontSizeLg * 0.9}px;
  margin: 0 0 15px 0;
`;

const Tabs = props => {
  const {
    content,
    onClick,
    className,
    components,
    activeTab,
    disabled,
    hasActiveTab = false,
  } = props;
  const [selectedTab, setSelectedTab] = useState(activeTab ? activeTab : 0);

  const handleClick = id => {
    if(hasActiveTab) {
      onClick(id);
    } else{
      if (!activeTab) {
        setSelectedTab(id);
      }
      onClick(id);
    }
  };

  const isSelected = id => {
    if(hasActiveTab) {
      return activeTab === id;
    } else {
      if (!activeTab) {
        return selectedTab === id;
      }
      return activeTab === id;
    }
  };

  const page = components ? components[activeTab || selectedTab] : null;

  return (
    <Wrapper>
      <Container className={className}>
        {content.map(({ id, title, visible = true }) => (
          <>
          {visible && (
            <Tab onClick={() => !disabled && handleClick(id)} key={id}>
              <TabTitle selected={isSelected(id)}>{title}</TabTitle>
              {isSelected(id) && <TabLine />}
            </Tab>
          )}  
          </>
        ))}
      </Container>
      <TabGreyLine width="100%" />
      {page}
    </Wrapper>
  );
};

Tabs.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  components: PropTypes.arrayOf(PropTypes.node),
  lineWidth: PropTypes.string,
};

Tabs.defaultProps = {
  onClick: () => {},
};

export default Tabs;

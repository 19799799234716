import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Tabs } from './';
// import { hexToRgb } from '../utils/hexToRgb';
import { theme } from '../styles/theme';
// import { StyledHeart, StyledOutline } from './styled';

const TextContainer = styled.div`
  border: 1px solid ${theme.colors.grey2};
  border-radius: 3px;
  padding: 1em;
  color: ${theme.colors.grey3};
  font-size: ${theme.fontSizeMed};
  width: 100%;
`;

const Title = styled.span`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSizeMed};
  font-weight: bold;
  display: block;
  margin-bottom: 0.5em;
`;

const AdBar = styled.div`
  background-color: ${theme.colors.text_heading};
  color: ${theme.colors.white};
  font-size: ${theme.fontSizeMed};
  font-weight: 300;
  width: 90%;
  padding: 1em;
  cursor: pointer;
  margin-bottom: 1em;
  text-align: center;
`;

const YellowText = styled.span`
  color: ${theme.colors.primary};
  font-weight: bold;
  margin: 0 0.3em;
`;

const ListItem = styled.li`
  text-decoration: none;
  list-style: none;
`;

const StyledTabs = styled(Tabs)`
  width: 100%;
  margin-top: 1.5em;
`;

const tabsContent = [
  {
    id: 0,
    title: 'DESCRIÇÃO',
  },
  {
    id: 1,
    title: 'RESUMO',
  },
];

const ContentComponent = ({ title, text }) => (
  <TextContainer>
    <Title>{title}</Title>
    {text}
  </TextContainer>
);

const AdvantagesDetails = ({ details, summary, moreDiscount, category }) => {
  const desc = <ul>{summary.filter(i => i !== "<br />").map((i, key) => <ListItem key={key}>{i}</ListItem>)}</ul>;

  const components = [
    <ContentComponent key={0} title="Descrição do Benefício" text={desc} />,
    <ContentComponent key={1} title="Resumo"
      text={details} />,
  ];

  return (
    <>
      <StyledTabs
        content={tabsContent}
        components={components}
        onClick={() => {}}
        lineWidth={'100%'}
      />
      {moreDiscount && category && (
        <AdBar>
          Economize mais
          <YellowText>R${moreDiscount && moreDiscount.toFixed(2)}</YellowText>
          tornando-se
          <YellowText>NOIVOS {category}</YellowText>
        </AdBar>
      )}
      {moreDiscount && !category && (
        <AdBar>
          Economia de
          <YellowText>R${moreDiscount && moreDiscount}</YellowText>
        </AdBar>
      )}
    </>
  );
};

AdvantagesDetails.propTypes = {
  details: PropTypes.string.isRequired,
};

export default AdvantagesDetails;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { theme } from '../styles/theme';
import { ExitButton, Backdrop } from './styled';
import { ArabescoStyles } from './CardContainer';

const Wrapper = styled.div`
  position: fixed;
  z-index: 100;
  min-height: 10vh;
  max-height: 90vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: ${props =>
    props.banner ? 'fit-content' : '750px'};
  padding: ${props => !props.banner && '1.2em 2em 4em'};
  overflow-y: auto;
  overflow-x: hidden;

  background-color: ${theme.colors.grey1};
  border: 1px solid ${theme.colors.grey2};
  border-radius: 15px;
  visibility: ${({ opened }) => (opened ? 'visible' : 'hidden')};

  ${({ hasArabesco }) =>
    hasArabesco
      ? `
    ${ArabescoStyles}
    ::after {
      content: none;
    }
    
  `
      : ''}
`;

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${({ hasArabesco }) => (hasArabesco ? '2.5em' : '1rem')};
  right: 1rem;
  padding: 0;
  ${ExitButton} {
    /* Resetando o left */
    left: auto;
  }
`;

const StyledExitButton = styled(ExitButton)`
  color: ${props =>
    props.banner ? theme.colors.white : theme.colors.error};
`;

export const ModalTitle = styled.h3`
  display: flex;
  align-self: center;
  margin: 0;
`;

const ModalContainer = ({
  closeModal,
  children,
  opened,
  hasArabesco = false,
  banner = false
}) => {
  const buttonEl = React.useRef(null);

  const closeOnEsc = React.useCallback(
    e => {
      var key = e.key || e.keyCode;
      if (key === 'Escape' || key === 'Esc' || key === 27) {
        closeModal();
      }
    },
    [closeModal]
  );
  
  React.useEffect(() => {
    if (opened) {
      document.documentElement.classList.add('html_modal-open');
      document.addEventListener('keydown', closeOnEsc);
      if (buttonEl && buttonEl.current) {
        buttonEl.current.focus();
      }
    } else {
      document.removeEventListener('keydown', closeOnEsc);
      document.documentElement.classList.remove('html_modal-open');
    }
    return () => {
      document.removeEventListener('keydown', closeOnEsc);
    };
  }, [closeOnEsc, opened]);
  return (
    <>
      {opened && <Backdrop aria-hidden={true} onClick={closeModal} />}
      <Wrapper opened={opened} aria-hidden={!opened} hasArabesco={hasArabesco} banner={banner}>
        <StyledButton
          ref={buttonEl}
          onClick={closeModal}
          title="Botão para fechar a janela"
          hasArabesco={hasArabesco}
        >
          <StyledExitButton left={'50%'} banner={banner}/>
        </StyledButton>
        {children}
      </Wrapper>
    </>
  );
};

ModalContainer.propTypes = {
  opened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  hasArabesco: PropTypes.bool,
};

ModalContainer.defaultProps = {
  opened: false,
};

export default ModalContainer;

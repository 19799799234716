import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/pt-br'; // without this line it didn't work
import FormControl from '@material-ui/core/FormControl';
import { navigate } from '@reach/router';

import {
  HeaderLogo,
  HeaderWrapper,
  HeaderNav,
  HeaderProfileImage,
  IconLink,
  TopHeaderContainer,
  UserInfo,
} from './HeaderComponents';
import {
  ExitIcon,
  UserIcon,
  RatingIcon,
  IndicateIcon,
  BenefitsIcon,
  FavoritesIcon,
  SearchIcon,
  CheckListIcon,
  EventIcon,
  HomeIcon,
  RewardIcon,
} from '../icons';
import HamburgerButton from '../HamburgerButton';
import { Backdrop } from '../styled';
import HeaderLinks from './HeaderLinks';
import { useStateValue } from '../../state';
import { MaterialSelect, MaterialMenuItem } from '../';
import { CategoryModal } from '../';
import { bride } from '../../services/api';

moment.locale('pt-br');

const BrideHeader = ({ logOut }) => {
  const [menuOpen, setMenu] = React.useState(false);
  const [categoryModal, setCategoryModal] = React.useState(false);

  const [
    {
      events: { eventRegions },
      session: {
        date,
        eventFinished,
        eventInactive,
        balance,
        category,
        userType,
        selectedRegion,
        selectedEvent,
      },
    },
    dispatch,
  ] = useStateValue();

  const { userName, firstLogin } = localStorage;
  const isGuest = userType === 'guest';

  const toggleMenu = () => {
    if (menuOpen) {
      document.documentElement.classList.remove('html-modal_open');
    } else {
      document.documentElement.classList.add('html_modal-open');
    }
    setMenu(!menuOpen);
  };

  const handleDateActive = () => {
    if (eventInactive === true) {
      return false;
    }

    const now = moment();
    const deadlineDate = now.subtract(30, 'days').format('YYYY-MM-DD');

    return deadlineDate <= date;
  };

  const handleActive = () => {
    if (eventFinished || eventInactive) {
      return false;
    }

    return true;
  };

  const brideMenuItems = [
    {
      label: 'Home',
      path: '/app/noivos/inicio',
      icon: <HomeIcon />,
      active: true,
    },
    {
      label: 'Orçamentos',
      path: '/app/noivos/orcamentos',
      icon: <CheckListIcon />,
      active: handleActive(),
    },
    {
      label: 'Fornecedores',
      path: '/app/noivos/fornecedores',
      icon: <SearchIcon viewBox="0 -5 25 25" header />,
      active: handleActive(),
    },
    {
      label: 'Favoritos',
      path: '/app/noivos/favoritos',
      icon: <FavoritesIcon />,
      active: handleActive(),
    },
    {
      label: 'Benefícios',
      path: '/app/noivos/vantagens',
      icon: <BenefitsIcon />,
      active: handleActive(),
    },
    {
      label: 'Prêmios',
      path: '/app/noivos/premios',
      icon: <RewardIcon />,
      active: handleDateActive(),
    },
    {
      label: 'Indicar',
      path: '/app/noivos/indicar',
      icon: <IndicateIcon />,
      included: !isGuest,
      active: true,
    },
    {
      label: 'Avaliações',
      path: '/app/noivos/avaliacoes',
      icon: <RatingIcon />,
      included: !isGuest,
      active: true,
    },
    {
      label: 'Meus Eventos',
      path: '/app/noivos/eventos',
      icon: <EventIcon />,
      included: !isGuest,
      active: true,
    },
    {
      label: 'Perfil',
      path: '/app/noivos/perfil',
      icon: <UserIcon />,
      included: !isGuest,
      active: true,
    },
  ];

  const onExitModal = () => {
    document.documentElement.classList.remove('html-modal_open');
    setMenu(false);
  };

  const renderRegionOptions = () =>
    eventRegions.map(({ codRegiao: { codRegiao, Regiao } }) => (
      <MaterialMenuItem value={codRegiao} key={codRegiao}>
        {Regiao}
      </MaterialMenuItem>
    ));

  const handleChangeRegion = async value => {
    const response = await bride.getEventPoints(selectedEvent, value);

    if (response.ok && response.data) {
      dispatch({
        type: 'changeSessionForm',
        field: 'balance',
        newText: response.data.balance,
      });
    }

    dispatch({
      type: 'changeSessionForm',
      field: 'selectedRegion',
      newText: value,
    });
  };

  return (
    <>
      {menuOpen && <Backdrop aria-hidden={true} onClick={toggleMenu} />}
      <HeaderWrapper menuOpen={menuOpen}>
        <TopHeaderContainer>
          <HeaderLogo
            path={firstLogin !== '1' ? '/app/noivos/inicio' : '/app/noivos'}
          />
          <HamburgerButton
            onClick={toggleMenu}
            aria-label={`Botão para ${menuOpen ? 'fechar' : 'abrir'} o menu`}
            isOpen={menuOpen}
          />
          <HeaderNav aria-label={`Menu ${menuOpen ? 'aberto' : 'fechado'}`}>
            {firstLogin !== '1' && (
              <>
                <HeaderProfileImage icon={<HomeIcon />} />
                <UserInfo>
                  <div>
                    <span className="title" aria-label="Nome do usuário">
                      {userName}
                    </span>
                  </div>
                  <FormControl style={{ width: '100%' }}>
                    <MaterialSelect
                      value={selectedRegion}
                      onChange={e => handleChangeRegion(e.target.value)}
                    >
                      {eventRegions && renderRegionOptions()}
                    </MaterialSelect>
                  </FormControl>
                </UserInfo>
                <UserInfo>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      navigate('/app/noivos/eventos', {
                        state: { opened: selectedEvent, field: 'points' },
                      })
                    }
                  >
                    <u>Pontos:</u> <span>{balance}</span>
                  </div>
                  <div>
                    <u
                      onClick={() => setCategoryModal(true)}
                      style={{ cursor: 'pointer' }}
                    >
                      Categoria
                    </u>
                    : {category}
                  </div>
                </UserInfo>
              </>
            )}
            <IconLink icon={ExitIcon}>
              <button onClick={logOut}>Sair</button>
            </IconLink>
          </HeaderNav>
        </TopHeaderContainer>
        {firstLogin !== '1' && (
          <HeaderLinks onExit={onExitModal} items={brideMenuItems} />
        )}
        <CategoryModal
          opened={categoryModal}
          closeModal={() => setCategoryModal(false)}
        />
      </HeaderWrapper>
    </>
  );
};

BrideHeader.propTypes = {
  logOut: PropTypes.func.isRequired,
  session: PropTypes.shape({
    stage: PropTypes.string.isRequired,
    count: PropTypes.string.isRequired,
    balance: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    category: PropTypes.string,
    userType: PropTypes.string.isRequired,
  }),
};

export default BrideHeader;

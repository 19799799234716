import React from 'react';
import styled from 'styled-components';

import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { TitleSubtitle } from './';
import { Link } from 'gatsby';
import Grid from '@material-ui/core/Grid';

const StyledLink = styled(Link)`
  :hover {
    background-color: ${theme.colors.secondary};
    color: ${theme.colors.white};
  }
  border: 1px solid ${theme.colors.secondary};
  border-radius: 3px;
  font-size: 0.7em;
  color: ${theme.colors.text_heading};
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 5em;
  font-weight: normal;
  margin: 5px 0 5px 0;
  text-decoration: none;
  font-weight: bold;
`;

const StyledButtonsTitle = styled.h4`
  @media ${medias.phoneMax} {
    font-size: 0.8em;
    width: 100%;
    margin-left: 20%; /* change this if possible. I couldnt get this result with flexbox */
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  }
  margin: ${props =>
    props.belowButtons ? '20px 0 14px 0' : '4px 0 14px 0'};
  text-align: center;
  
`;

const JoinUs = ({ belowButtons = false }) => {
  return (
    <>
      {!belowButtons && (
        <Grid container spacing={32}>
          <Grid item xs={8} sm={8} md={8}>
            <TitleSubtitle />
          </Grid>

          <Grid item xs={4} sm={4} md={4}>
            <StyledButtonsTitle>FAÇA PARTE COMO</StyledButtonsTitle>
            <StyledLink to="/facaparte/noivo">NOIVO(A)</StyledLink>
            <StyledLink to="/facaparte/fornecedor">FORNECEDOR</StyledLink>
            <StyledLink to="/facaparte/franqueado">FRANQUEADO</StyledLink>
          </Grid>
        </Grid>
      )}
      {belowButtons && (
        <>
          <TitleSubtitle />
          <StyledButtonsTitle belowButtons={belowButtons}>FAÇA PARTE COMO</StyledButtonsTitle>
          <Grid container spacing={32}>
            <Grid item xs={12} sm={4} md={4}>
              <StyledLink to="/facaparte/noivo">NOIVO(A)</StyledLink>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <StyledLink to="/facaparte/fornecedor">FORNECEDOR</StyledLink>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <StyledLink to="/facaparte/franqueado">FRANQUEADO</StyledLink>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default JoinUs;

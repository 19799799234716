import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { theme } from '../styles/theme';
import {
  Button,
  MaterialInput,
  QuoteItem,
  MoneyMask,
} from './';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import { bride } from '../services/api';
import ModalContainer from './ModalContainer';
import { CenteredText } from './pageComponents';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    width: 90%;
  }
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5em;
  ${({ borderBottom }) =>
    borderBottom &&
    `border-bottom: 1px solid ${theme.colors.grey3}; padding-bottom: 1.5em;`}
`;

const Title = styled.h3`
  display: flex;
  align-self: center;
  margin-top: 0;
  margin: ${({ last }) => (last ? '0.7em' : '0')} 0 0 0;
`;

const StyledInput = styled(MaterialInput)`
  width: 45%;
`;

const StyledButton = styled(Button)`
  border: 2px solid ${theme.colors.text_heading};
  background-color: ${theme.colors.text_heading};
  color: ${theme.colors.white};
  width: 45%;
  height: 1.8em;
  padding: 0;
  ${({ add }) => add && `margin-top: 0.5em;`}
`;

const QuotesModal = ({ segmentId, closeModal, finished, opened }) => {
  const [supplierName, setSupplierName] = useState('');
  const [supplierValue, setSupplierValue] = useState('');
  const [reasonValue, setReasonValue] = useState('');
  const [quotes, setQuotes] = useState(null);

  const { doUpdate } = useFetch({
    url: urls.bridesContent.getQuotes(segmentId),
    method: method.GET,
    condition: () => !quotes,
    callback: data => setQuotes(data),
  });

  const cleanForm = () => {
    setSupplierValue('');
    setSupplierName('');
    setReasonValue('');
  };

  const onClickAdd = () => {
    if (!supplierName) {
      toast.error('Favor informar o nome da empresa.');
      return;
    }
    saveNewQuote();
    cleanForm();
  };

  const saveNewQuote = async () => {
    const payload = {
      segmentId,
      supplier: supplierName,
      value: supplierValue,
    };
    if (reasonValue) {
      payload['declinedReason'] = reasonValue;
    }
    try {
      const response = await bride.newOutsideQuote(payload);
      if (response) {
        toast.success('Novo orçamento registrado.');
        setQuotes(null);
        doUpdate();
      }
    } catch (error) {
      toast.error('Erro ao registrar novo orçamento.');
    }
  };

  const renderList = () => (
    <>
      {
        !quotes
          ? <CenteredText>Carregando dados...</CenteredText>
          : quotes.length > 0
            ? quotes.map(item => {
              const { id, name, value, motivoNaoFechamento, filled } = item;
              return (
                <QuoteItem
                  key={id}
                  id={id}
                  name={name}
                  value={value}
                  motivoNaoFechamento={motivoNaoFechamento}
                  filled={filled}
                  reloadFunction={setQuotes}
                  updateFunction={doUpdate}
                />
              );
            })
            : <CenteredText>Sem registros</CenteredText>
      }
    </>
  );

  return (
    <ModalContainer opened={opened} closeModal={closeModal} hasArabesco>
      <Wrapper>

        <Title>ORÇAMENTOS RECEBIDOS</Title>
        {renderList()}
        {!finished && (
          <>
            <Title last>ADICIONAR ORÇAMENTOS</Title>
            <RowContainer>
              <StyledInput
                onChange={event => setSupplierName(event.target.value)}
                value={supplierName}
                label="Empresa"
              />
              <MaterialInput
                style={{ width: '45%' }}
                label="Valor"
                onChange={event => setSupplierValue(event.target.value)}
                value={supplierValue}
                InputProps={{
                  inputComponent: MoneyMask,
                }}
              />
            </RowContainer>
            <StyledButton add text="ADICIONAR" click={onClickAdd} />
          </>
        )}
      </Wrapper>
    </ModalContainer>
  );
};

// QuotesModal.propTypes = {
//   data: PropTypes.arrayOf(Object).isRequired,
// }

export default QuotesModal;

// Styled components altamente reutilizáveis e simples
import React from 'react';
import styled from 'styled-components';
import CloseButton from 'react-icons/lib/io/ios-close-outline';
import { Link } from 'gatsby';
import { Link as ReachLink } from '@reach/router';

import { theme } from '../../styles/theme';
import { Favorite, FavoriteOutline } from '../icons';
import { Button } from '../';
import Arabesco from '../../images/arabescos.svg';
import { medias } from '../../utils/mediaQueries';

export const ExitButton = styled(CloseButton)`
  height: 21px;
  width: 21px;
  color: ${theme.colors.error};
  cursor: pointer;
  position: relative;
  /* left: 95%; */
  left: ${props => props.left};
  top: ${props => props.top};
`;

export const TabLine = styled.div`
  background-color: ${theme.colors.grey2};
  height: 2px;
  margin: 1px 0 1px 0;
`;

export const HeroWrapper = styled.div`
  min-height: 100vh;
`;

export const StyledHeart = styled(props => <Favorite {...props} />)`
  ${props => (props.secondary ? '' : 'cursor: pointer')};
`;

export const StyledOutline = styled(props => <FavoriteOutline {...props} />)`
  ${props => (props.secondary ? '' : 'cursor: pointer')};
`;

export const Title = styled.h4`
  font-size: ${theme.fontSizeLg * 0.9}px;
  font-weight: ${theme.bodyWeight};
  margin: 40px 0 15px 0;
`;

export const Line = styled.div`
  height: 3px;
  width: 60px;
  background-color: ${theme.colors.primary};
  margin: 0 0 40px 0;
`;

export const ListDivisor = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${theme.colors.grey1};
  margin: 0 0 40px 0;
`;

export const StyledButton = styled(props => <Button {...props} />)`
  width: 93%;
  background-color: ${theme.colors.text_heading};
  color: ${theme.colors.white};
  margin: 20px 0 0 0;
  border-color: ${theme.colors.text_heading};
`;

export const StyledHalfButton = styled(props => <Button {...props} />)`
  width: 48%;
  background-color: ${theme.colors.text_heading};
  color: ${theme.colors.white};
  margin: 20px 0 0 0;
  border-color: ${theme.colors.text_heading};
`;

export const RedButton = styled(props => <Button {...props} />)`
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.error};
  border: 2px solid ${theme.colors.error};
  margin-bottom: 0.75em;
`;

export const NoDecorationLink = styled(Link)`
  text-decoration: none;
`;

export const NoDecorationReachLink = styled(ReachLink)`
  text-decoration: none;
`;

export const AssetContainer = styled.div`
  @media ${medias.tabletMin} {
    background-size: contain;
  }

  background-image: url(${Arabesco});
  background-size: cover;
  background-repeat-x: repeat;
  width: 100%;
  height: 2em;
  ${({ bottom }) => (bottom ? 'margin-top: 1em;' : 'margin-bottom: 1em;')}
`;

export const Backdrop = styled.div`
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
`;

import React from 'react';
import styled from 'styled-components';

import { medias } from '../utils/mediaQueries';
import { theme } from '../styles/theme';
import { Grid } from '@material-ui/core';

const Wrapper = styled.div`
  @media ${medias.largeMin} {
    width: ${props => (props.isHomePage ? '52%' : '58%')};
    top: ${props => (props.isHomePage ? '90%' : '')};
    left: ${props => (props.isHomePage ? '24%' : '')};
  }
  position: absolute;
  width: 56%;
  z-index: 10;
  left: 4%;
`;

const StyledContainer = styled(Grid)`
  @media ${medias.smallerTabletMax} {
    height: ${props => (props.length <= 14 ? 'auto' : '400px')};
    overflow-y: ${props => (props.length <= 14 ? 'hidden' : 'scroll')};
  }
  margin-top: 24px;
  margin-bottom: 10px;
  background: #f0f0f0;
  box-shadow: 0px 3px 10px 0px rgba(205, 204, 204, 0.5);
  overflow-y: ${props => (props.isTyping ? 'hidden' : 'scroll')};
  height: ${props => (props.isTyping ? 'auto' : '400px')};
  padding-top: 12px;
  border-radius: 4px;
`;

const StyledItem = styled(Grid)`
  cursor: pointer;
  font-size: 0.8em;
  :hover {
    background-color: ${theme.colors.grey1};
  }
`;

const StyledText = styled.p`
  display: flex;
  justify-content: flex-start;
  margin: 0 0 0 1em;
`;

const BubbleDiv = styled.div`
  margin-top: 24px;
  position: relative;
  :after {
    content: '';
    position: absolute;
    top: 1px;
    left: 6%;
    width: 0;
    height: 0;
    border: 16px solid transparent;
    border-bottom-color: #f0f0f0;
    border-top: 0;
    margin-left: -16px;
    margin-top: -16px;
  }
`;

const StyledIcon = styled.img`
  width: 25px;
  height: 25px;
  fill: ${({ color }) => color};
`;

const SegmentsCointainer = ({ segments, onClick, isHomePage, isTyping }) => {
  const itemSize = segments.length <= 12 ? 12 : 6;
  return (
    <Wrapper isHomePage={isHomePage}>
      <BubbleDiv>
        <StyledContainer
          container
          spacing={16}
          isTyping={isTyping}
          length={segments.length}
        >
          {!isTyping && (
            <StyledItem
              item
              xs={12}
              sm={itemSize}
              md={itemSize}
              onMouseDown={() => onClick('Todos')}
            >
              <StyledText>
                <strong>Todos</strong>
              </StyledText>
            </StyledItem>
          )}
          {segments.length > 0 &&
            segments.map(item => (
              <StyledItem
                item
                xs={12}
                sm={itemSize}
                md={itemSize}
                key={item.codSegmento}
                onMouseDown={() =>  onClick(item.Segmento)}
              >
                <StyledText>
                  <StyledIcon src={item.linkIcone} />
                  {item.Segmento}
                </StyledText>
              </StyledItem>
            ))}
        </StyledContainer>
      </BubbleDiv>
    </Wrapper>
  );
};

export default SegmentsCointainer;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import {
  MaterialSelect,
  MaterialInputLabel,
  MaterialMenuItem,
  MaterialInput,
  MaterialDatePicker,
} from '../components';
import FormControl from '@material-ui/core/FormControl';
import { toast } from 'react-toastify';
import MomentUtils from '@date-io/moment';

import { useStateValue } from '../state';
import { StyledButton, StyledHalfButton } from '../components/styled';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import Arabesco from '../images/arabescos.svg';
import { hexToRgb } from '../utils/hexToRgb';
import { REGEX_EMAIL } from '../utils/regex';
import { siteContent, joinUs } from '../services/api';
import { urls } from '../services/urls';
import useFetch, { method } from '../hooks/useFetch';
import phoneMask from '../utils/phoneMask';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin: 0 0 50px 0;
  width: 100%;
`;

const AssetContainer = styled.div`
  @media ${medias.tabletMin} {
    background-size: contain;
  }

  background-image: url(${Arabesco});
  background-size: cover;
  background-repeat-x: repeat;
  width: 100%;
  height: 2em;
  ${({ bottom }) =>
    bottom
      ? 'margin-top: 1em; border-bottom-left-radius: 10px;  border-bottom-right-radius: 10px;'
      : 'margin-bottom: 1em;'}
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  background-color: ${theme.colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 90%;
`;
const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  width: 100%;
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${theme.colors.grey2};
`;

const Title = styled.h4`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSizeLg * 0.85}px;
  margin: 0 0 1em 0;
`;

const StyledHalfInput = styled(InputMask)`
  width: 45%;
`;

const StyledFormControl = styled(props => <FormControl {...props} />)`
  width: 45%;
`;

const StyledSpan = styled.p`
  margin: 0;
  width: 30%;
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
`;

const Checkbox = styled.input`
  margin-right: 0.5em;
`;

const StyledFieldset = styled.div`
  width: 100%;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin-top: 0.25em;
`;

const StyledFields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
`;

const StyledDatePicker = styled(props => <MaterialDatePicker {...props} />)`
  width: 45%;
`;

const BrideForm = ({ format }) => {
  const [activityDropDown, setActivityDropDown] = useState(false);
  const [activePage, setActivePage] = useState(0);
  const [supplierOrigin, setSupplierOrigin] = useState([]);
  const [regions, setRegions] = useState([]);
  const [segments, setSegments] = useState([]);
  const [availableCities, setAvailableCities] = useState(null);

  const [
    {
      forms: { supplierForm },
      generalData: { states },
    },
    dispatch,
  ] = useStateValue();
  const {
    cnpj,
    publicityName,
    socialReason,
    city,
    state,
    brideEmail,
    bridePhone,
    instagram,
    webPage,
    resume,
    comercialPhone,
    comercialEmail,
    companyStart,
    activityType,
    otherActivity,
    codSupplierOrigin,
    weddingsPerMonth,
    contractAveragePrice,
    weddingsPerDay,
    ceremonialPartners,
    suppliersPartners,
    regionOfInterest,
  } = supplierForm;

  const fetchSupplierOrigin = async () => {
    let _supplierOrigin;
    const response = await siteContent.suppliersOrigin();
    if (response.ok && response.data) {
      _supplierOrigin = response.data;
    }
    setSupplierOrigin(_supplierOrigin);
  };

  useFetch({
    url: urls.siteContent.states,
    method: method.GET,
    condition: () => !states,
    callback: data =>
      dispatch({
        type: 'setStates',
        newStates: data,
      }),
  });

  const fetchRegions = async () => {
    let _regions;
    const response = await siteContent.regions();
    if (response.ok && response.data) {
      _regions = response.data;
    }
    setRegions(_regions);
  };

  const fetchSegments = async () => {
    let _segments;
    const response = await siteContent.segments();
    if (response.ok && response.data) {
      _segments = response.data;
    }

    const sortedSegments = _segments.sort((a, b) => {
      if (a.Segmento > b.Segmento) {
        return 1;
      }

      if (a.Segmento < b.Segmento) {
        return -1;
      }

      return 0;
    });

    setSegments(sortedSegments);
  };

  const fetchCities = async codUf => {
    const response = await siteContent.cities(codUf);
    response.ok && response.data
      ? setAvailableCities(response.data)
      : toast.error('Não foi possivel recuperar as cidades!');
  };

  useEffect(() => {
    fetchSupplierOrigin();
    fetchRegions();
    fetchSegments();
  }, []);

  const SendSuppliersPost = async () => {
    // eslint-disable-next-line
    let {
      city,
      activityType,
      otherActivity,
      ceremonialPartners,
      suppliersPartners,
      companyStart,
      cnpj,
      instagram,
      webPage,
      ...payload
    } = supplierForm;
    let newCompany = companyStart.toISOString('YYYY-MM-DD').split('T');

    if (otherActivity) activityType = otherActivity;
    const body = {
      ...payload,
      codIBGE: city,
      communicationEmail: brideEmail,
      comercialContact: comercialEmail,
      activityType,
      companyStart: newCompany[0],
      ceremonialPartners: `${ceremonialPartners[0]}/${ceremonialPartners[1]}/${
        ceremonialPartners[2]
      }`,
      suppliersPartners: `${suppliersPartners[0]}/${suppliersPartners[1]}/${
        suppliersPartners[2]
      }`,
      statusProcess: 1,
      cnpj: cnpj.replace(/[^0-9 ]/g, ''),
    };

    delete body.state;

    if (instagram) {
      body.instagram = instagram;
    }

    if (webPage) {
      body.webPage = webPage;
    }

    const response = await joinUs.waitingListSupplier(body);
    if (response.ok) {
      toast.success('Formulário enviado com sucesso!', {
        onClose: () => window.location.reload(),
      });
      return;
    }
    if (response.status === 400) {
      toast.error(
        'Houve um erro na submissão! Favor verificar se os campos foram preenchidos corretamente.'
      );
      return;
    }

    if (response.status === 409) {
      toast.error('Este CNPJ já está cadastrado na lista de espera.');
      return;
    }

    toast.error('Houve um erro na submissão do cadastro.');
  };

  const handleChange = (newText, field) => {
    dispatch({
      type: 'changeSupplierForm',
      newText,
      field,
    });
  };

  const handleSegments = value => {
    const { Segmento, codSegmento } = segments.find(
      ({ Segmento }) => Segmento === value
    );
    handleChange(Segmento, 'activityType');
    handleChange(parseInt(codSegmento), 'segmentOfInterest');
  };

  const handleArrayChange = (newText, field, position) => {
    const newArray =
      field === 'ceremonialPartners' ? ceremonialPartners : suppliersPartners;
    const changedArray = {
      ...newArray,
      [position]: newText,
    };
    dispatch({
      type: 'changeSupplierForm',
      newText: changedArray,
      field,
    });
  };

  return (
    <Wrapper>
      <Card>
        <AssetContainer />
        {activePage === 0 && (
          <>
            <TitleContainer>
              <Title>Fornecedor - Usuários</Title>
            </TitleContainer>
            <Line />
            <InputContainer>
              <RowContainer>
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Razão social"
                  required
                  value={socialReason}
                  onChange={event =>
                    handleChange(event.target.value, 'socialReason')
                  }
                />
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Nome fantasia"
                  required
                  value={publicityName}
                  onChange={event =>
                    handleChange(event.target.value, 'publicityName')
                  }
                />
              </RowContainer>
              <RowContainer>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <StyledDatePicker
                    keyboard
                    label="Data de abertura da empresa"
                    required
                    mask={value =>
                      value
                        ? [
                            /\d/,
                            /\d/,
                            '/',
                            /\d/,
                            /\d/,
                            '/',
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]
                        : []
                    }
                    value={companyStart}
                    format={format || 'DD/MM/YYYY'}
                    onChange={companyStart =>
                      handleChange(companyStart, 'companyStart')
                    }
                    disableOpenOnEnter
                    animateYearScrolling={false}
                  />
                </MuiPickersUtilsProvider>
                <StyledHalfInput
                  label="CNPJ"
                  required
                  mask="99.999.999/9999-99"
                  value={cnpj}
                  onChange={event => handleChange(event.target.value, 'cnpj')}
                >
                  {inputProps => <MaterialInput {...inputProps} />}
                </StyledHalfInput>
              </RowContainer>
              <RowContainer>
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Email"
                  required
                  value={brideEmail}
                  onChange={event =>
                    handleChange(event.target.value, 'brideEmail')
                  }
                />
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Email comercial"
                  required
                  value={comercialEmail}
                  onChange={event =>
                    handleChange(event.target.value, 'comercialEmail')
                  }
                />
              </RowContainer>
              <RowContainer>
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Telefone"
                  required
                  value={bridePhone}
                  onChange={event =>
                    handleChange(phoneMask(event.target.value), 'bridePhone')
                  }
                />
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Telefone comercial"
                  required
                  onChange={event =>
                    handleChange(
                      phoneMask(event.target.value),
                      'comercialPhone'
                    )
                  }
                  value={comercialPhone}
                />
              </RowContainer>
              <RowContainer>
                <StyledFormControl>
                  <MaterialInputLabel htmlFor="state-simple">
                    Estado *
                  </MaterialInputLabel>
                  <MaterialSelect
                    onChange={event => {
                      fetchCities(event.target.value);
                      handleChange(event.target.value, 'state');
                    }}
                    value={state}
                    inputProps={{
                      name: 'state',
                      id: 'state-simple',
                    }}
                  >
                    {states
                      ? states.map(({ name, cod }) => (
                          <MaterialMenuItem key={cod} value={cod}>
                            {name}
                          </MaterialMenuItem>
                        ))
                      : null}
                  </MaterialSelect>
                </StyledFormControl>
                <StyledFormControl>
                  <MaterialInputLabel htmlFor="city-simple">
                    Cidade *
                  </MaterialInputLabel>
                  <MaterialSelect
                    onChange={event => handleChange(event.target.value, 'city')}
                    value={city}
                    inputProps={{
                      name: 'city',
                      id: 'city-simple',
                    }}
                  >
                    {availableCities
                      ? availableCities.map(({ nomeCidade, codCidadeIBGE }) => (
                          <MaterialMenuItem
                            key={codCidadeIBGE}
                            value={codCidadeIBGE}
                          >
                            {nomeCidade}
                          </MaterialMenuItem>
                        ))
                      : null}
                  </MaterialSelect>
                </StyledFormControl>
              </RowContainer>
              <StyledButton
                text="AVANÇAR"
                click={() => {
                  if (
                    !publicityName ||
                    !socialReason ||
                    !cnpj ||
                    !brideEmail ||
                    !companyStart ||
                    !comercialPhone ||
                    !bridePhone ||
                    !state ||
                    !city ||
                    !comercialEmail
                  ) {
                    toast.error('Todos os campos são obrigatórios!');
                    return false;
                  }

                  if (!REGEX_EMAIL.test(brideEmail)) {
                    toast.error('Digite um e-mail válido!');
                    return false;
                  }
                  setActivePage(1);
                }}
              />
            </InputContainer>
          </>
        )}
        {activePage === 1 && (
          <>
            <TitleContainer>
              <Title>Fornecedor - Dados Básicos</Title>
            </TitleContainer>
            <Line />
            <InputContainer>
              <RowContainer>
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Instagram"
                  value={instagram}
                  onChange={event =>
                    handleChange(event.target.value, 'instagram')
                  }
                />
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Site"
                  value={webPage}
                  onChange={event =>
                    handleChange(event.target.value, 'webPage')
                  }
                />
              </RowContainer>
              <MaterialInput
                inputProps={{
                  maxLength: 500,
                }}
                label="Resumo da empresa"
                style={{ width: '100%' }}
                multiline
                rows="4"
                value={resume}
                required
                onChange={event => handleChange(event.target.value, 'resume')}
              />
              <RowContainer>
                <StyledFormControl>
                  <MaterialInputLabel htmlFor="know-simple">
                    Onde conheceu *
                  </MaterialInputLabel>
                  <MaterialSelect
                    onChange={event =>
                      handleChange(event.target.value, 'codSupplierOrigin')
                    }
                    value={codSupplierOrigin}
                    inputProps={{
                      name: 'codSupplierOrigin',
                      id: 'know-simple',
                    }}
                  >
                    {supplierOrigin &&
                      supplierOrigin.map(
                        ({ codOrigemFornecedor, OrigemFornecedor }) => (
                          <MaterialMenuItem
                            value={codOrigemFornecedor}
                            key={codOrigemFornecedor}
                          >
                            {OrigemFornecedor}
                          </MaterialMenuItem>
                        )
                      )}
                  </MaterialSelect>
                </StyledFormControl>
                <StyledFormControl>
                  <MaterialInputLabel htmlFor="region-simple">
                    Região de interesse *
                  </MaterialInputLabel>
                  <MaterialSelect
                    onChange={event =>
                      handleChange(event.target.value, 'regionOfInterest')
                    }
                    value={regionOfInterest}
                    inputProps={{
                      name: 'regionOfInterest',
                      id: 'region-simple',
                    }}
                  >
                    {regions &&
                      regions.map(({ codRegiao, Regiao }) => (
                        <MaterialMenuItem value={codRegiao} key={codRegiao}>
                          {Regiao}
                        </MaterialMenuItem>
                      ))}
                  </MaterialSelect>
                </StyledFormControl>
              </RowContainer>
              <RowContainer>
                <StyledFields>
                  <StyledFormControl
                    style={{ width: '90%' }}
                    disabled={activityDropDown}
                  >
                    <MaterialInputLabel htmlFor="activity-simple">
                      Área de atuação *
                    </MaterialInputLabel>
                    <MaterialSelect
                      onChange={event => handleSegments(event.target.value)}
                      value={activityType}
                      inputProps={{
                        name: 'activityType',
                        id: 'activity-simple',
                      }}
                    >
                      {segments &&
                        segments.map(({ Segmento, codSegmento }) => (
                          <MaterialMenuItem value={Segmento} key={codSegmento}>
                            {Segmento}
                          </MaterialMenuItem>
                        ))}
                    </MaterialSelect>
                  </StyledFormControl>
                  <StyledFieldset>
                    <Checkbox
                      type="checkbox"
                      name="hasDate"
                      value="Não definida"
                      onChange={() => setActivityDropDown(!activityDropDown)}
                      checked={activityDropDown}
                    />
                    <StyledSpan>Outros</StyledSpan>
                  </StyledFieldset>
                </StyledFields>
                {activityDropDown ? (
                  <MaterialInput
                    style={{ width: '45%' }}
                    label="Área de atuação"
                    value={otherActivity}
                    hidden={!activityDropDown}
                    onChange={event =>
                      handleChange(event.target.value, 'otherActivity')
                    }
                    required
                  />
                ) : null}
              </RowContainer>

              <RowContainer>
                <StyledHalfButton
                  text="VOLTAR"
                  click={() => setActivePage(0)}
                />
                <StyledHalfButton
                  text="AVANÇAR"
                  click={() => {
                    if (
                      !resume ||
                      !regionOfInterest ||
                      !codSupplierOrigin ||
                      (!otherActivity && !activityType)
                    ) {
                      toast.error('Preencha todos os campos obrigatórios!');
                      return false;
                    }

                    setActivePage(2);
                  }}
                />
              </RowContainer>
            </InputContainer>
          </>
        )}
        {activePage === 2 && (
          <>
            <TitleContainer>
              <Title>Fornecedor - Dados Complementares</Title>
            </TitleContainer>
            <Line />
            <InputContainer>
              <RowContainer>
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Quantidade de noivos por mês"
                  value={weddingsPerMonth}
                  onChange={event =>
                    handleChange(
                      parseInt(event.target.value),
                      'weddingsPerMonth'
                    )
                  }
                  required
                />
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Média de valor de contratos"
                  value={contractAveragePrice}
                  onChange={event =>
                    handleChange(
                      parseInt(event.target.value),
                      'contractAveragePrice'
                    )
                  }
                  required
                />
              </RowContainer>
              <MaterialInput
                style={{ width: '100%' }}
                label="Numéro de casamentos que realiza no mesmo dia"
                value={weddingsPerDay}
                onChange={event =>
                  handleChange(parseInt(event.target.value), 'weddingsPerDay')
                }
                required
              />
              <RowContainer>
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Três cerimoinais próximos"
                  required
                  value={ceremonialPartners[0]}
                  onChange={event =>
                    handleArrayChange(
                      event.target.value,
                      'ceremonialPartners',
                      0
                    )
                  }
                />
                <MaterialInput
                  style={{ width: '45%' }}
                  label="Três fornecedores parceiros"
                  required
                  value={suppliersPartners[0]}
                  onChange={event =>
                    handleArrayChange(
                      event.target.value,
                      'suppliersPartners',
                      0
                    )
                  }
                />
              </RowContainer>
              <RowContainer>
                <MaterialInput
                  style={{ width: '45%' }}
                  required
                  value={ceremonialPartners[1]}
                  onChange={event =>
                    handleArrayChange(
                      event.target.value,
                      'ceremonialPartners',
                      1
                    )
                  }
                />
                <MaterialInput
                  style={{ width: '45%' }}
                  required
                  value={suppliersPartners[1]}
                  onChange={event =>
                    handleArrayChange(
                      event.target.value,
                      'suppliersPartners',
                      1
                    )
                  }
                />
              </RowContainer>
              <RowContainer>
                <MaterialInput
                  style={{ width: '45%' }}
                  required
                  value={ceremonialPartners[2]}
                  onChange={event =>
                    handleArrayChange(
                      event.target.value,
                      'ceremonialPartners',
                      2
                    )
                  }
                />
                <MaterialInput
                  style={{ width: '45%' }}
                  required
                  value={suppliersPartners[2]}
                  onChange={event =>
                    handleArrayChange(
                      event.target.value,
                      'suppliersPartners',
                      2
                    )
                  }
                />
              </RowContainer>
              <RowContainer>
                <StyledHalfButton
                  text="VOLTAR"
                  click={() => setActivePage(1)}
                />
                <StyledHalfButton
                  text="FINALIZAR"
                  click={() => {
                    if (
                      !weddingsPerMonth ||
                      !contractAveragePrice ||
                      !weddingsPerDay ||
                      !ceremonialPartners[0] ||
                      !ceremonialPartners[1] ||
                      !ceremonialPartners[2] ||
                      !suppliersPartners[0] ||
                      !suppliersPartners[1] ||
                      !suppliersPartners[2]
                    ) {
                      toast.error('Todos os campos são obrigatórios!');
                      return false;
                    } else {
                      SendSuppliersPost();
                    }

                    setActivePage(2);
                  }}
                />
              </RowContainer>
            </InputContainer>
          </>
        )}
        <AssetContainer bottom />
      </Card>
    </Wrapper>
  );
};

BrideForm.propTypes = {
  format: PropTypes.string,
};

export default BrideForm;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import {
  CirclePlusIcon,
  CircleMinusIcon,
  ConfirmIcon,
  RedExitIcon,
} from './icons';
import { ChecklistCard } from '../components';


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${({ wrapperSize }) => (wrapperSize ? wrapperSize : '100%')};
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 0 0 1.5em;

  cursor: pointer;
`;

const Line = styled.div`
  background-color: ${theme.colors.grey1};
  height: 1px;
  width: 100%;
  margin-bottom: 0.8em;
`;

const SegmentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0.8em 0 0.8em 0;
  padding-left: 0.8em;
`;

const ItemTitle = styled.h4`
  @media ${medias.phoneMax} {
    font-size: 0.8em;
  }

  font-size: ${theme.fontSize * 1.06}px;
  font-weight: bold;
  color: ${theme.colors.text_heading};
  margin: 0;
`;

const AddItemContainer = styled.div`
  background-color: ${theme.colors.text_heading};
  margin: 10px;
  padding: 2% 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ContainerElements = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled.input`
  @media ${medias.phoneMax} {
    font-size: 0.75em;
  }

  background-color: transparent;
  border: none;
  font-family: Lato;
  font-size: ${theme.fontSize}px;
  line-height: 2.38;
  letter-spacing: -0.4px;
  text-align: left;
  color: ${theme.colors.white};
  margin: 5px;
`;

const StyledLine = styled.div`
  height: 2px;
  margin: 2px;
  background-color: ${theme.colors.white};
`;
const StyledLineContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
`;
//@TODO Slide-up and slide-down transitions
const SegmentListAccordion = props => {
  const {
    title,
    items,
    wrapperSize,
    //selected,
    suppliers,
    updatePage
  } = props;

  const [data, setData] = useState(items);
  const [openedStage, setOpenedStage] = useState(true);
  //const [selectedSegments,setSelectedSegments] = useState([]);
  const [addSegment, setAddSegment] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setData(items);
  }, [items]);

  // useEffect(() => {
  //   setSelectedSegments(selected);
  // },[selected]);


  const handleConfirmSegment = () => {
    const newSegment = {
      codSegmento: 0,
      Segmento: inputValue,
      linkIcone:
        'https://storage.googleapis.com/assets-vdb/bemCasadoPaoMel.svg',
      inativo: 0,
      faseSegmento: data[0].faseSegmento,
    };

    const newData = [...data, newSegment];

    setData(newData);
    setInputValue('');
  };

  const handleAddSegment = () => {
    return (
      <AddItemContainer>
        <StyledLineContainer>
          <StyledInput
            type="text"
            value={inputValue}
            placeholder="Digite o nome do segmento"
            onChange={event => setInputValue(event.target.value)}
          />
          <StyledLine />
        </StyledLineContainer>
        <ConfirmIcon
          style={{ cursor: 'pointer' }}
          onClick={() => handleConfirmSegment()}
        />
        <RedExitIcon
          style={{ cursor: 'pointer' }}
          onClick={() => setAddSegment(false)}
        />
      </AddItemContainer>
    );
  };

  return (
    <Wrapper wrapperSize={wrapperSize}>
      <Item>
        <Line />
        <TitleContainer onClick={() => setOpenedStage(!openedStage)}>
          {openedStage ? <CircleMinusIcon /> : <CirclePlusIcon />}
          <ItemTitle>{title}</ItemTitle>
        </TitleContainer>
        {openedStage && (
          <ContainerElements>
            <SegmentContainer>
            {
              (data && data.length > 0)
                ? data.map(checklistItem => (
                  <ChecklistCard
                    key={checklistItem.id}
                    data={checklistItem}
                    suppliers={suppliers}
                    updatePage={updatePage}
                  />
                ))
                : <p>Nenhum segmento</p>
            }
            </SegmentContainer>
            {addSegment && handleAddSegment()}
          </ContainerElements>
        )}
      </Item>
      <Line />
    </Wrapper>
  );
};

// SegmentListAccordion.propTypes = {
//   title: PropTypes.string.isRequired,
//   items: PropTypes.arrayOf(
//     PropTypes.shape({
//       title: PropTypes.string.isRequired,
//       icon: PropTypes.string.isRequired,
//     })
//   ),
//   include: PropTypes.boolean,
// };

// SegmentListAccordion.defaultProps = {
// };

export default SegmentListAccordion;

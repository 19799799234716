import React, { useEffect, useState, useCallback } from 'react';
import { useFormState } from 'react-use-form-state';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import { toast } from 'react-toastify';

import { bride } from '../services/api';
import { urls } from '../services/urls';
import useFetch, { method } from '../hooks/useFetch';
import { siteContent } from '../services/api';
import phoneMask from '../utils/phoneMask';

import {
  MaterialInput,
  MaterialDatePicker,
  MaterialSelect,
  MaterialMenuItem,
  MaterialInputLabel,
  Button,
} from '../components';
import { theme } from '../styles/theme';
import { useStateValue } from '../state';

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5em;
`;

const SelectGroup = styled(props => <FormControl {...props} />)`
  display: flex;
  flex-direction: column;
  width: 47.5%;
`;

const RadioLabel = styled.label`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSizeSm}px;
  text-align: center;
  margin: 0;
`;

const StyledLabel = styled.label`
  color: ${theme.colors.grey3};
  font-size: ${theme.fontSizeSm}px;
  margin: 0;
  text-align: left;
`;

const RadioInput = styled.input`
  margin: 0;
  margin-right: 0.3em;
`;

const RadioSection = styled.fieldset`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border: none;
  width: 50%;
  padding: 0;
  margin: 0.5em 0;
`;

const InputsSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: none;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-bottom: 0.5em;
`;

const StyledDatePicker = styled(props => <MaterialDatePicker {...props} />)`
  margin: 0.6rem 0;
  width: 100%;
`;

const StyledButton = styled(props => <Button {...props} />)`
  width: 47.5%;
  background-color: ${theme.colors.text_heading};
  border-color: ${theme.colors.text_heading};
  color: white;
  font-size: ${theme.fontSizeSm}px;
`;

const ButtonsContainer = styled.section`
  display: flex;
  justify-content: space-around;
  margin-top: 0.5em;
  width: 100%;
`;

const StyledForm = styled.form`
  width: 90%;
`;

const StyledFieldset = styled.div`
  width: 100%;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin-top: 0.25em;
`;

const Checkbox = styled.input`
  margin-right: 0.5em;
`;

const StyledSpan = styled.p`
  margin: 0;
  width: 30%;
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
`;

const RequestBudgetForm = ({ supplierCod }) => {
  const { userId } = localStorage;

  const [authenticated, setAuthentication] = useState(false);
  const [contact, setContact] = useState(false);
  const [availableCities, setAvailableCities] = useState(null);

  const initialFormState = {
    codContactMe: '1',
    uf: 0,
    city: 0,
  };

  const [
    {
      forms: { requestBudgetForm },
      generalData: { states },
      session: { selectedEvent },
    },
    dispatch,
  ] = useStateValue();

  const {
    name,
    email,
    phone,
    weddingGuests,
    codIBGE, //eslint-disble-line
    codContactMe, //eslint-disble-line
  } = requestBudgetForm;

  const checkAuthentication = useCallback(() => {
    userId ? setAuthentication(true) : setAuthentication(false);
  }, [userId]);

  useEffect(() => {
    dispatch({
      type: 'changeBudgetForm',
      field: 'codContactMe',
      newText: initialFormState.codContactMe,
    });
    checkAuthentication();
  }, [checkAuthentication, dispatch, initialFormState.codContactMe]);

  const [formState, { text, radio, select, label }] = useFormState(
    initialFormState,
    {
      withIds: true,
      onChange(e) {
        const { name, value } = e.target;

        if (name !== 'phone') {
          dispatch({
            type: 'changeBudgetForm',
            field: name,
            newText: value,
          });
        }
      },
    }
  );

  useFetch({
    url: urls.siteContent.states,
    method: method.GET,
    condition: () => !states,
    callback: data =>
      dispatch({
        type: 'setStates',
        newStates: data,
      }),
  });

  const findCurrentEvent = e => {
    let event = e.filter(event => event.codEvento === selectedEvent);
    return event[0];
  };

  const authenticatedFormHandler = async () => {
    const response = await bride.userInfo();
    let payload = response.data;
    let event = findCurrentEvent(payload.events);
    const { codIBGE, email, name, phone } = payload;
    const { eventDate, weddingGuests, cerimonyLocation } = event;
    const { message, codContactMe } = requestBudgetForm;
    const body = {
      name,
      email,
      phone,
      weddingGuests,
      codIBGE,
      codContactMe,
      cerimonyLocation,
      eventDate,
      codSupplier: supplierCod,
      message,
    };
    return body;
  };

  const notAuthenticatedFormHandler = () => {
    const {
      uf, // eslint-disable-line
      weddingGuests,
      eventDate,
      codContactMe,
      codIBGE, //eslint-disable-line
      ...payload
    } = requestBudgetForm;

    let newDate;
    if (eventDate) newDate = eventDate.toISOString('YYYY-MM-DD').split('T');

    const body = {
      ...payload,
      weddingGuests: parseInt(weddingGuests),
      eventDate: eventDate ? newDate[0] : null,
      codSupplier: supplierCod,
      contact: parseInt(codContactMe),
    };

    return body;
  };

  const sendBudget = async () => {
    if (authenticated) {
      const body = await authenticatedFormHandler();
      const response = await bride.budget(body);
      response.ok && response.data
        ? toast.success('Orçamento enviado com sucesso!', {
            onClose: () => window.location.reload(),
          })
        : toast.error('Não foi possivel enviar o orçamento!');
      return;
    }
    const body = await notAuthenticatedFormHandler();
    const response = await siteContent.budget(body);
    response.ok && response.data
      ? toast.success('Orçamento enviado com sucesso!', {
          onClose: () => window.location.reload(),
        })
      : toast.error('Não foi possivel enviar o orçamento!');
    return;
  };

  const handlePhoneChange = input => {
    const newText = phoneMask(input);

    dispatch({
      type: 'changeBudgetForm',
      field: 'phone',
      newText,
    });
  };

  const fetchCities = async codUf => {
    const response = await siteContent.cities(codUf);
    response.ok && response.data
      ? setAvailableCities(response.data)
      : toast.error('Não foi possivel recuperar as cidades!');
  };

  return (
    <StyledForm onSubmit={() => console.log(formState)}>
      {!authenticated && (
        <>
          <p style={{ textAlign: 'center' }}>Solicitar orçamento</p>
          <MaterialInput
            {...text('name')}
            style={{ width: '100%' }}
            required
            label="Seu nome"
          />
          <MaterialInput
            {...text('email')}
            required
            style={{ width: '100%' }}
            label="Seu melhor e-mail"
          />
          <MaterialInput
            {...text({
              name: 'phone',
              onChange: event => handlePhoneChange(event.target.value),
            })}
            required
            style={{ width: '100%' }}
            label="Telefone"
          />
          <StyledDatePicker
            label="Data do casamento"
            format={'DD/MM/YYYY'}
            disablePast
            value={requestBudgetForm.eventDate}
            onChange={eventDate =>
              dispatch({
                type: 'changeBudgetForm',
                field: 'eventDate',
                newText: eventDate,
              })
            }
          />
          <InputsSection>
            <SelectGroup>
              <MaterialInputLabel {...label('uf')}>Estado</MaterialInputLabel>
              <MaterialSelect
                {...select({
                  name: 'uf',
                  validate: () => {},
                  onChange: e => fetchCities(e.target.value),
                })}
              >
                <MaterialMenuItem value={0}>Selecione</MaterialMenuItem>
                {states
                  ? states.map(({ name, cod }) => (
                      <MaterialMenuItem key={cod} value={cod}>
                        {name}
                      </MaterialMenuItem>
                    ))
                  : null}
              </MaterialSelect>
            </SelectGroup>
            <SelectGroup>
              <MaterialInputLabel {...label('city')}>Cidade</MaterialInputLabel>
              <MaterialSelect
                {...select({
                  name: 'city',
                  validate: () => {},
                })}
              >
                <MaterialMenuItem value={0}>Selecione</MaterialMenuItem>
                {availableCities
                  ? availableCities.map(({ nomeCidade, codCidadeIBGE }) => (
                      <MaterialMenuItem
                        key={codCidadeIBGE}
                        value={codCidadeIBGE}
                      >
                        {nomeCidade}
                      </MaterialMenuItem>
                    ))
                  : null}
              </MaterialSelect>
            </SelectGroup>
          </InputsSection>
          <InputsSection>
            <MaterialInput
              {...text('cerimonyLocation')}
              required
              style={{ width: '47.5%' }}
              label="Local do evento"
            />
            <MaterialInput
              {...text('weddingGuests')}
              required
              style={{ width: '47.5%' }}
              label="Nº de convidados"
            />
          </InputsSection>
        </>
      )}
      <MaterialInput
        {...text('message')}
        style={{ width: '100%' }}
        label="Mensagem"
      />
      <StyledLabel>Quero ser contactado</StyledLabel>
      <RadioSection>
        <RadioGroup>
          <RadioInput {...radio('codContactMe', '1')} />
          <RadioLabel {...label('codContactMe', 'phone')}>Telefone</RadioLabel>
        </RadioGroup>
        <RadioGroup>
          <RadioInput {...radio('codContactMe', '2')} />
          <RadioLabel {...label('codContactMe', 'whatsapp')}>
            Whatsapp
          </RadioLabel>
        </RadioGroup>
        <RadioGroup>
          <RadioInput {...radio('codContactMe', '3')} />
          <RadioLabel {...label('codContactMe', 'mail')}>E-mail</RadioLabel>
        </RadioGroup>
      </RadioSection>
      {authenticated && (
        <StyledFieldset>
          <Checkbox
            type="checkbox"
            name="hasDate"
            value="Não definida"
            onChange={() => setContact(!contact)}
            checked={contact}
          />
          <StyledSpan>Já entrei em contato com esse fornecedor</StyledSpan>
        </StyledFieldset>
      )}
      <ButtonsContainer>
        <StyledButton
          text="SOLICITAR"
          click={() => {
            if (
              !authenticated &&
              (!name ||
                !email ||
                !phone ||
                !weddingGuests ||
                !codIBGE ||
                !codContactMe)
            ) {
              toast.error('Preencha todos os dados obrigatórios!');
            } else {
              sendBudget();
            }
          }}
        />
      </ButtonsContainer>
    </StyledForm>
  );
};

export default RequestBudgetForm;

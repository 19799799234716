export const urls = {
  baseUrl: process.env.GATSBY_API, // eslint-disable-line
  siteContent: {
    faq: '/site-content/faq',
    advantages: '/site-content/advantages',
    advantagesDiscount: '/site-content/advantages-discounts',
    advantagesSegments: '/site-content/advantages-segments',
    testimonials: '/site-content/testimonial',
    regions: `/site-content/regions`,
    protocolTypes: `/site-content/protocol-types`,
    applicantTypes: `/site-content/applicant-types`,
    suppliers: `/site-content/suppliers`,
    supplierSegments: `/site-content/suppliers-segments`,
    segments: `/site-content/segments`,
    cities: codUf =>
      codUf === '' ? `/site-content/cities` : `/site-content/cities/${codUf}`,
    states: `/site-content/states`,
  },
  userdata: {
    login: '/userdata/login',
    supplierLogin: '/userdata/supplier-login',
    userInfo: '/userdata/user-info',
    weddingInfo: '/userdata/user-wedding',
  },
  bridesContent: {
    brideEvents: `/bride/events`,
    usersFromEvent: codEvent => `/bride/users-from-event?event=${codEvent}`,
    eventRegions: codEvento => `/bride/event-regions?event=${codEvento}`,
    eventAvaliableRegions: codEvento =>
      `/bride/event-avaliable-regions?event=${codEvento}`,
    bridePrizes: (codEvent, codRegiao) =>
      `/bride/prizes?codEvent=${codEvent}&codRegiao=${codRegiao}`,
    bridePrizesInfo: id => `/bride/prize-info?codPrize=${id}`,
    eventPoints: codEvent => `/bride/event-points?codEvent=${codEvent}`,
    checkIndication: codEvent =>
      `/bride/person-avaliable-indication?codEvent=${codEvent}`,
    checklistSegments: codEvento =>
      `/bride/checklist-segments?event=${codEvento}`,
    getEventDate: codEvento =>
      `/bride/person-current-event-date?codEvent=${codEvento}`,
    getUserInfo: `/bride/person-info`,
    brideAdvantages: codEvent => `/bride/advantages?codEvent=${codEvent}`,
    getQuotes: codSegment => `/bride/get-quotes/${codSegment}`,
    getRecomendations: (codSegment, codRegion) =>
      `/supplier/get-recomendations?codSegment=${codSegment}&codRegion=${codRegion}`,
    getPointsHistory: (codEvento, codRegiao) =>
      `/event-card/point-history?event=${codEvento}&region=${codRegiao}`,
    getClosedSegments: codEvento =>
      `/event-card/closed-segments?event=${codEvento}`,
    favoritesSuppliers: codEvent =>
      `/bride/favorites-suppliers?codEvent=${codEvent}`,
    favoritesAdvantages: codEvent =>
      `/bride/favorites-advantages?codEvent=${codEvent}`,
    favoritesPrizes: codEvent => `/bride/favorites-prizes?codEvent=${codEvent}`,
    suppliers: codEvent => `/bride/suppliers?codEvent=${codEvent}`,
    redemeedPrizes: codEvent => `/bride/redeemed-prizes?codEvent=${codEvent}`,
    rattingSupport: codEvent => `/bride/ratting-support?codEvent=${codEvent}`,
    ratingeExecution: codEvent =>
      `/bride/ratting-execution?codEvent=${codEvent}`,
    ratingePrize: codEvent => `/bride/ratting-prizes?codEvent=${codEvent}`,
    prioritySegments: codEvent =>
      `/bride-home-page/get-priority-segments?event=${codEvent}`,
    finishedSegments: codEvent =>
      `/bride-home-page/get-finished-segments?event=${codEvent}`,
    getSegmentsWithRegisteredSuppliers: `/bride/get-segments-with-registered-suppliers`,
  },
  supplierContent: {
    redeemedPrizes: () => '/supplier-prizes/get-redeemed-prizes',
    stockedPrizes: () => '/supplier-prizes/get-stocked-prizes',
    prizeDataConsolidation: () => '/supplier-prizes/prizes-consolidation',
    getRewards: `/supplier-rewards/rewards`,
    pointsHistory: `/supplier-rewards/get-points-history`,
    getOpportunitiesBrides: () => `/suppliers-opportunities/get-brides`,
    getOpportunitiesFilteredBrides: month =>
      `/supplier/get-events?month=${month}&type=opportunities`,
    getOpportunitiesReport: '/suppliers-opportunities/get-opportunities-data',
    opportunitiesConsolidation: () =>
      '/suppliers-opportunities/get-opportunities-data',
    avaliableBridesConsolidation: () => '/supplier/get-filtered-brides',
    getBridesFilteredByType: (order, type, page, resPerPage) =>
      `/supplier/get-filtered-brides?order=${order}&type=${type}&page=${page}&resPerPage=${resPerPage}`,
    contracts: (filter, type, filterData) => {
      if (filter === 'year') {
        return `/supplier/contracts?type=${type}&year=${filterData}`
      } 
      if (filter === 'nameOrEmail') {
        return `/supplier/contracts?type=${type}&nameOrEmail=${filterData}`
      } 
      if (filter === 'weddingDate') {
        return `/supplier/contracts?type=${type}&initialDate=${filterData.from}&finalDate=${filterData.to}`
      } 
    },
    contractsDataConsolidation: () => '/supplier/contracts-consolidation/',
    getSupplierEvents: month => `/supplier/get-events?month=${month}`,
    advantages: `/supplier/advantages`,
    supplierRegions: `/supplier-data/get-supplier-regions`,
    supplierSegments: `/supplier-data/supplier-segments`,
    advantagesConsolidation: `/supplier/advantages-consolidation`,
    supplierData: `/supplier-data/data`,
    supplierGuests: `/supplier-data/guest`,
    historyPoints: `/supplier-data/point-history`,
    supplierIndicationAmount: `/supplier-data/indication-amount`,
    getBrides: '/supplier/get-brides',
    getOtherSuppliers: '/supplier-data/get-suppliers',
    getContactedBrides: '/supplier-data/get-contacted-brides',
    getBridesFilteredByDate: (order, type, page, resPerPage, initialDate, finalDate) => `/supplier/get-filtered-brides?order=${order}&type=${type}&page=${page}&resPerPage=${resPerPage}&initialDate=${initialDate}&finalDate=${finalDate}`,
  },
};

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import { theme } from '../../styles/theme';
import { medias } from '../../utils/mediaQueries';

export const HeaderLinksWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0.25rem 0.5rem;
  overflow-x: auto;
  link {
    margin: 0 0.25em 0 0;
  }
  @media ${medias.largeMin} {
    justify-content: space-around;
  }
`;

const HeaderItem = styled.div`
  color: ${theme.colors.secondary};
  font-weight: 500;
  font-size: ${theme.fontSize}px;
  white-space: nowrap;
  padding: 0.5em;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 1.55em;
    max-height: 1.5em;
    margin-right: 0.35em;
    fill: ${theme.colors.primary};
  }

  :active {
    cursor: default;
    color: ${theme.colors.primary};
  }

  :disabled {
    cursor: default;
    color: ${theme.colors.grey3};
  }
`;

const HeaderLinks = ({ items, onExit }) => {
  const onClickLink = path => {
    onExit();
    navigate(path);
  };

  return (
    <HeaderLinksWrapper>
      {items.map(({ label, icon, path, active = true, included = true, visible = true }) =>
          <>
          {included && visible && (
            <HeaderItem
            key={path}
            as={active ? undefined : 'button'}
            disabled={!active}
            onClick={() => onClickLink(path)}
            >
            {icon}
            <span>{label}</span>
            </HeaderItem>
          )}
          </>
      )}
    </HeaderLinksWrapper>
  );
};

HeaderLinks.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      included: PropTypes.bool,
      path: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.func.isRequired,
    })
  ),
  onExit: PropTypes.func,
};

HeaderLinks.defaultProps = {
  onExit: () => {},
};

export default HeaderLinks;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

import { theme } from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { Button, MaterialInput, MaterialInputLabel } from './';
import { bride } from '../services/api';
import ModalContainer from './ModalContainer';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: ${({ tab }) => (tab ? 'space-evenly ' : 'space-around')};
  align-items: center;
  width: ${({ tab }) => (tab ? '95%' : '100%')};
  ${({ tab }) =>
    tab &&
    `
    border-bottom: 1px solid ${theme.colors.grey2}
    margin-bottom: 0.5em;
  `}
`;

const TabName = styled.h4`
  @media ${medias.phoneMax} {
    font-size: 0.55em;
  }

  font-size: ${theme.fontSize * 0.94}px;
  color: ${theme.colors.text_heading};
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  cursor: pointer;
`;

const StyledInput = styled(MaterialInput)`
  width: 45%;
`;

const StyledButton = styled(Button)`
  background-color: transparent;
  border: 2px solid black;
  width: 45%;
  margin-top: 1em;
`;

const StyledFormControl = styled(props => <FormControl {...props} />)`
  @media ${medias.phoneMax} {
    width: 90%;
  }

  width: 50%;
`;

const ModalTabs = ({ onSelectTab }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTab = id => () => {
    setSelectedTab(id);
    onSelectTab(id);
  };

  return (
    <>
      <RowContainer tab>
        <TabName selected={selectedTab === 0} onClick={handleTab(0)}>
          QUANTIDADE DE PONTOS
        </TabName>
        <TabName selected={selectedTab === 1} onClick={handleTab(1)}>
          SEGMENTO
        </TabName>
        <TabName selected={selectedTab === 2} onClick={handleTab(2)}>
          FORNECEDOR
        </TabName>
      </RowContainer>
    </>
  );
};

export const PrizesFiltersModal = ({ onClickFilter, onCloseModal, opened }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [values, setValues] = useState({
    minValue: '',
    maxValue: '',
  });
  const [segment, setSegment] = useState('');
  const [segmentsData, setSegmentsData] = useState(null);
  const [supplier, setSupplier] = useState('');
  const [suppliersData, setSuppliersData] = useState(null);

  useEffect(() => {
    const fethSuppliers = async () => {
      const response = await bride.getPrizesSuppliers();

      if (response.data && response.ok) {
        setSuppliersData(response.data);
      }
    };

    fethSuppliers();
  }, []);

  useEffect(() => {
    const fetchSegments = async () => {
      const response = await bride.getPrizesSegments();

      if (response.data && response.ok) {
        setSegmentsData(response.data);
      }
    };

    fetchSegments();
  }, []);

  const cleanForm = () => {
    setValues({
      minValue: '',
      maxValue: '',
    });
    setSegment('');
    setSupplier('');
  };

  const handleFilter = () => {
    if (selectedTab === 0) {
      const data = values;
      onClickFilter(selectedTab, data);
      return;
    }

    if (selectedTab === 1) {
      const data = segment;
      onClickFilter(selectedTab, data);
      return;
    }

    if (selectedTab === 2) {
      const data = supplier;
      onClickFilter(selectedTab, data);
      return;
    }
  };

  const renderContent = () => {
    const { minValue, maxValue } = values;

    if (selectedTab === 1) {
      return (
        <>
          <StyledFormControl>
            <MaterialInputLabel htmlFor="segment-native-simple">
              Selecione o segmento
            </MaterialInputLabel>
            <NativeSelect
              onChange={event => setSegment(event.target.value)}
              value={segment}
              inputProps={{
                name: 'segment',
                id: 'segment-native-simple',
              }}
            >
              <option value="" />
              {segmentsData &&
                segmentsData.map(({ Segmento, codSegmento }) => (
                  <option value={Segmento} key={codSegmento}>
                    {Segmento}
                  </option>
                ))}
            </NativeSelect>
          </StyledFormControl>
        </>
      );
    } else if (selectedTab === 2) {
      return (
        <>
          <StyledFormControl>
            <MaterialInputLabel htmlFor="supplier-native-simple">
              Selecione o fornecedor
            </MaterialInputLabel>
            <NativeSelect
              onChange={event => setSupplier(event.target.value)}
              value={supplier}
              inputProps={{
                name: 'supplier',
                id: 'supplier-native-simple',
              }}
            >
              <option value="" />
              {suppliersData &&
                suppliersData.map(({ nomeDivulgacao, codFornecedor }) => (
                  <option value={nomeDivulgacao} key={codFornecedor}>
                    {nomeDivulgacao}
                  </option>
                ))}
            </NativeSelect>
          </StyledFormControl>
        </>
      );
    } else {
      return (
        <RowContainer>
          <StyledInput
            onChange={event =>
              setValues({ ...values, minValue: event.target.value })
            }
            value={minValue}
            label="Quantidade mínima"
          />
          <StyledInput
            onChange={event =>
              setValues({ ...values, maxValue: event.target.value })
            }
            value={maxValue}
            label="Quantidade máxima"
          />
        </RowContainer>
      );
    }
  };

  return (
    <ModalContainer
      opened={opened}
      closeModal={onCloseModal}
      hasArabesco={true}
    >
      <ModalTabs onSelectTab={id => setSelectedTab(id)} />
      <Wrapper>
        {renderContent()}
        <RowContainer>
          <StyledButton text="LIMPAR" click={() => cleanForm()} />
          <StyledButton text="FILTRAR" click={handleFilter} />
        </RowContainer>
      </Wrapper>
    </ModalContainer>
  );
};

PrizesFiltersModal.propTypes = {
  suppliersData: PropTypes.arrayOf(Object).isRequired,
  segmentsData: PropTypes.arrayOf(Object).isRequired,
};

export default PrizesFiltersModal;

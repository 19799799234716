import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import Arabesco from '../images/arabescos.svg';
import { medias } from '../utils/mediaQueries';
import { Button } from './';
import { hexToRgb } from '../utils/hexToRgb';
import { theme } from '../styles/theme';
import { StyledHeart, StyledOutline } from './styled';
import { AdvantagesDetails } from './';
import { MaterialInput } from '../components';
import { bride } from '../services/api';
import { useStateValue } from '../state';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  margin: 0 0 1em 0;
  background-color: ${theme.colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const AssetContainer = styled.div`
  @media ${medias.tabletMin} {
    background-size: contain;
  }

  background-image: url(${Arabesco});
  background-size: cover;
  background-repeat-x: repeat;
  width: 100%;
  height: 2em;
  ${({ bottom }) =>
    bottom
      ? 'margin-top: 1em; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; '
      : 'margin-bottom: 1em;'}
`;

const HalfButton = styled(props => <Button {...props} />)`
  @media ${medias.phoneMax} {
    height: 40px;
    font-size: 10.6px;
  }
  background-color: ${props =>
    props.selected ? theme.colors.white : theme.colors.text_heading};
  color: ${props =>
    props.selected ? theme.colors.text_heading : theme.colors.white};
  border-color: ${theme.colors.text_heading};
  width: 45%;
  font-size: ${theme.fontSizeSm}px;
  text-align: center;
`;

const TopContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 90%;
`;

const ImageContainer = styled.div`
  @media ${medias.laptopMin} {
    width: 40%;
    height: 25%;
  }

  @media ${medias.phoneMax} {
    width: 40%;
    height: 25%;
  }

  width: 269px;
  height: 151px;
`;

const Image = styled.img`
  width: 100%;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 1em 0 0.5em 0;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const SegmentContainer = styled.div`
  @media ${medias.phoneMax} {
    width: 50%;
  }

  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`;

const StyledIcon = styled.img`
  width: 30px;
  height: 30px;
  fill: ${({ color }) => color};
`;

const Title = styled.span`
  @media ${medias.phoneMax} {
    font-size: ${theme.fontSizeSm}px;
    margin-left: 5px;
  }
  font-size: ${theme.fontSize}px;
  color: ${theme.colors.text_heading};
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.5em;
`;

const SubTitle = styled.span`
  @media ${medias.phoneMax} {
    font-size: ${theme.fontSizeSm}px;
    margin-left: 5px;
  }
  font-size: ${theme.fontSize}px;
  color: ${theme.colors.grey3};
  font-weight: 300;
  text-align: left;
  margin-bottom: 0.5em;
`;

const Savings = styled.span`
  font-size: ${theme.fontSize}px;
  color: ${theme.colors.primary};
  font-weight: bold;
  text-align: left;
  margin-left: 0.5em;
`;

const ItemText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.65em;
    width: 40%;
  }

  display: flex;
  align-self: center;
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.text_heading};
  margin: 0 0 0 0.5em;
  word-wrap: break-word;
`;

const RadioSection = styled.fieldset`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: none;
  width: 90%;
  padding: 0;
  margin: 0 0 1em 0;
  @media ${medias.phoneMax} {
    flex-direction: column;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5em;
`;

const RadioInput = styled.input`
  margin: 0;
  margin-right: 0.3em;
`;

const RadioLabel = styled.label`
  color: ${theme.colors.text_heading};
  font-size: ${theme.fontSizeSm}px;
  text-align: center;
  margin: 0;
`;

const ContactLabel = styled.span`
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.grey3};
  font-weight: 300;
  margin-right: 1em;
`;

const ConfirmButton = styled(props => <HalfButton {...props} />)`
  width: 90%;
  align-self: center;
`;

const AdvantagesCard = ({
  advantageData,
  category,
  removeFavorite,
  addFavorite,
}) => {
  const [contact, setContact] = useState(0);
  const [message, setMessage] = useState('');
  const [moreDiscount, setMoreDiscount] = useState(null);
  const [categoryDiscount, setDiscount] = useState(null);
  const [budgetOpened, setBudgetOpened] = useState(false);
  const [detailsOpened, setDetailsOpened] = useState(false);
  const [categoryForMore, setCategoryForMore] = useState(null);
  const [favorited, setFavorited] = useState(advantageData.isFavorited);

  const [
    {
      session: { selectedEvent },
    },
  ] = useStateValue();

  useEffect(() => {
    if (!categoryDiscount) {
      const _discountLight = (discount / 100) * 80;
      const _discountPlus = (discount / 100) * 90;
      if (category === 'LIGHT') {
        setDiscount(_discountLight.toFixed(2));
        setMoreDiscount(_discountPlus - _discountLight);
        setCategoryForMore('PLUS');
      } else if (category === 'PLUS') {
        setDiscount(_discountPlus.toFixed(2));
        setMoreDiscount(discount - _discountPlus);
        setCategoryForMore('VIP');
      } else {
        setDiscount(discount.toFixed(2));
      }
    }
  }, [category, categoryDiscount, discount]);

  const {
    codAdvantage,
    name,
    description,
    supplierName,
    discount,
    segment,
    codSegment,
    image,
    linkSegment,
    resume
  } = advantageData;

  const onClickConfirm = async () => {
    const body = {
      userId: localStorage.userId,
      event: selectedEvent,
      contact,
      message,
      supplierName,
      advantage: codAdvantage,
      codSegment,
      value: categoryDiscount,
    };

    const response = await bride.brideAdvantageBudget(body);

    response.ok && response.data
      ? toast.error('Orçamento solicitado com sucesso!')
      : toast.error('Não foi possivel solicitar o orçamento!');
  };

  const renderHeartIcon = () => {
    if (favorited) {
      return (
        <StyledHeart
          onClick={() => {
            setFavorited(!favorited);
            removeFavorite(codAdvantage, 'advantages');
          }}
        />
      );
    } else {
      return (
        <StyledOutline
          onClick={() => {
            setFavorited(!favorited);
            addFavorite(codAdvantage, 'advantages');
          }}
        />
      );
    }
  };

  return (
    <Wrapper>
      <AssetContainer />
      <TopContainer>
        <ImageContainer>
          {image && <Image src={image} alt="Advantage Image" />}
        </ImageContainer>
        <DataContainer>
          <Title>{name}</Title>
          <SubTitle>{supplierName}</SubTitle>
          <SegmentContainer key={codSegment}>
            <StyledIcon src={linkSegment} />
            <ItemText>{segment}</ItemText>
          </SegmentContainer>
          <SubTitle>
            Economize
            <Savings>R$ {categoryDiscount}</Savings>
          </SubTitle>
        </DataContainer>
        {renderHeartIcon()}
      </TopContainer>
      {detailsOpened && (
        <AdvantagesDetails
          moreDiscount={moreDiscount}
          category={categoryForMore}
          details={description}
          summary={resume.split('<br />')}
          termsOfUse={termsOfUse}
        />
      )}
      <ButtonsContainer>
        <HalfButton
          text="DETALHES"
          click={() => setDetailsOpened(!detailsOpened)}
          selected={detailsOpened}
        />
        <HalfButton
          text="EU QUERO"
          click={() => setBudgetOpened(!budgetOpened)}
          selected={budgetOpened}
        />
      </ButtonsContainer>
      {budgetOpened && (
        <>
          <MaterialInput
            style={{ width: '90%' }}
            label="Mensagem"
            value={message}
            onChange={event => setMessage(event.target.value)}
          />
          <RadioSection>
            <ContactLabel>Quero ser contactado:</ContactLabel>
            <RadioGroup>
              <RadioInput
                type="radio"
                onChange={() => setContact(1)}
                value="phone"
                checked={contact === 1}
              />
              <RadioLabel>Telefone</RadioLabel>
            </RadioGroup>
            <RadioGroup>
              <RadioInput
                type="radio"
                onChange={() => setContact(3)}
                value="whatsapp"
                checked={contact === 3}
              />
              <RadioLabel>Whatsapp</RadioLabel>
            </RadioGroup>
            <RadioGroup>
              <RadioInput
                type="radio"
                onChange={() => setContact(2)}
                value="mail"
                checked={contact === 2}
              />
              <RadioLabel>E-mail</RadioLabel>
            </RadioGroup>
          </RadioSection>
          <ConfirmButton text="CONFIRMAR" click={onClickConfirm} />
        </>
      )}
      <AssetContainer bottom />
    </Wrapper>
  );
};

const termsOfUse = (
  <ul>
    <li>
      Clique em Eu Quero caso deseje aproveitar esse Benefício, dessa forma sua
      solicitação de orçamento será em cima desse Benefício. Caso já tenha
      aproveitado não há a necessidade de clicar. O contrato fechado pode ser
      informado no Menu Orçamentos, dentro do segmento adequado e assim seus
      pontos serão creditados
    </li>
    <li>
      Benefício sujeito a disponibilidade de datas por parte do fornecedor.
    </li>
    <li>
      O Benefício é nominal e poderá ser utilizado apenas por esses noivos.
    </li>
    <li>Agendamento obrigatório.</li>
    <li>Não acumulativo com outras promoções.</li>
    <li>
      As imagens contidas nesta oferta são meramente ilustrativas, salvo aquelas
      expressamente identificadas como sendo reais.
    </li>
  </ul>
);

AdvantagesCard.propTypes = {
  advantageData: PropTypes.shape({
    codAdvantage: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    supplierName: PropTypes.string.isRequired,
    discount: PropTypes.number.isRequired,
    segment: PropTypes.string.isRequired,
    codSegment: PropTypes.number.isRequired,
    isFavorited: PropTypes.bool.isRequired,
    image: PropTypes.string.isRequired,
    linkSegment: PropTypes.string.isRequired,
  }),
};

export default AdvantagesCard;

import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import Button from './Button';
import { FavoriteStarIcon, FavoriteStarEmptyIcon } from './icons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 0 0 1.5em;

  cursor: pointer;
`;

const SegmentItem = styled.div`
  @media ${medias.phoneMax} {
    width: 42%;
  }

  display: flex;
  flex-direction: row;
  width: 30%;
  justify-content: flex-start;
  align-items: center;
  margin: 0.5em 0.5em;

  ${({ disabled }) => !disabled && `cursor: pointer;`}
`;

const SegmentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0.8em 0 0.8em 0;
  padding-left: 0.8em;
`;

const ItemTitle = styled.h4`
  @media ${medias.phoneMax} {
    font-size: 0.8em;
  }

  font-size: ${theme.fontSize * 1.06}px;
  font-weight: bold;
  color: ${theme.colors.text_heading};
  margin: 0;
`;

const ItemText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.65em;
    margin: 0 0 0 5px;
  }

  display: flex;
  align-self: center;
  width: 83%;
  font-size: ${theme.fontSizeLg * 0.9}px;
  color: ${theme.colors.text_heading};
  margin: 0 0 0 10px;
  overflow: hidden;
`;

const StyledIcon = styled.img`
  width: 30px;
  height: 30px;
  fill: ${({ color }) => color};
`;

const ItemContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
`;

const ContainerElements = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled(Button)`
  background-color: ${theme.colors.text_heading};
  border: 2px solid ${theme.colors.text_heading};
  color: white;
  height: 2em;
  width: 90%;
  margin-top: 1em;
  padding: 0;
`;

const StyledFavoriteStarIcon = styled(FavoriteStarIcon)`
  cursor: pointer;
  margin-left: 0.5em;
`;

const StyledFavoriteStarEmptyIcon = styled(FavoriteStarEmptyIcon)`
  cursor: pointer;
  margin-left: 0.5em;
`;

const PrioritySegmentList = ({ data, onClickConfirm }) => {
  const { initialStage, middleStage, finalStage } = data.segments;
  const [selectedSegments, setSelectedSegments] = useState(data.priority);

  const isSelected = id => selectedSegments.includes(id);

  const handleClickSegment = id => {
    if (selectedSegments.includes(id)) {
      const newSelectedSegment = selectedSegments.filter(item => item !== id);
      return setSelectedSegments(newSelectedSegment);
    }

    if (selectedSegments.length < 3) {
      const newSelectedSegment = [...selectedSegments, id];
      return setSelectedSegments(newSelectedSegment);
    }

    toast.error('Selecione no máximo 3 segmentos!');
  };

  const renderFavoriteStar = id =>
    isSelected(id) ? (
      <StyledFavoriteStarIcon />
    ) : (
      <StyledFavoriteStarEmptyIcon />
    );

  const SegmentList = ({ list, title }) => {
    return (
      <Wrapper>
        <Item>
          <TitleContainer>
            <ItemTitle>{title}</ItemTitle>
          </TitleContainer>
          <ContainerElements>
            <SegmentContainer>
              {list.map(({ Segmento, codSegmento, linkIcone }) => (
                <SegmentItem
                  key={codSegmento}
                  onClick={() => handleClickSegment(codSegmento)}
                >
                  <ItemContainer>
                    <StyledIcon src={linkIcone} />
                    <ItemText>{Segmento}</ItemText>
                  </ItemContainer>
                  {renderFavoriteStar(codSegmento)}
                </SegmentItem>
              ))}
            </SegmentContainer>
          </ContainerElements>
        </Item>
      </Wrapper>
    );
  };

  return (
    <Container>
      <SegmentList title="Fase Inicial" list={initialStage} />
      <SegmentList title="Fase Intermediária" list={middleStage} />
      <SegmentList title="Fase Final" list={finalStage} />
      <StyledButton
        text="CONFIRMAR"
        click={() => onClickConfirm(selectedSegments)}
      />
    </Container>
  );
};

export default PrioritySegmentList;

import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { Button } from './';
import { MaterialInput, MoneyMask } from '../components';
import dateUndefined from '../utils/undefinedDate';

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ last }) =>
    !last &&
    `border-bottom: 1px solid ${theme.colors.grey2}; margin-bottom: 1em`};

  padding: 0 0 1em 0;

  h3 {
    color: ${theme.colors.text_heading};
    align-self: flex-start;
    margin: 0;
  }
`;

const CardTitle = styled.div`
  @media ${medias.phoneMax} {
    font-size: ${theme.fontSizeSm}px;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em 0;
  h3 {
    color: ${theme.colors.text_heading};
    margin: 0;
    margin-right: 1em;
  }

  p {
    color: ${theme.colors.grey3};
    font-size: 1em;
    margin: 0;
  }
  @media ${medias.phoneMax} {
    h3 {
      margin-bottom: 10px;
    }
  }
`;

const DataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: ${theme.colors.grey3};
    font-size: 1em;

    strong {
      color: ${theme.colors.primary};
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledButton = styled(props => <Button {...props} />)`
  width: 45%;
  height: 2.5em;
  padding: 0;
  font-size: 0.7em;
  background-color: ${theme.colors.text_heading};
  color: ${theme.colors.white};
  border: 2px solid ${theme.colors.text_heading};
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SegmentContainer = styled.div`
  @media ${medias.phoneMax} {
    width: 50%;
    margin-left: 30px;
  }
  margin-left: 50px;
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: flex-start;
`;

const StyledIcon = styled.img`
  width: 30px;
  height: 30px;
  fill: ${({ color }) => color};
`;

const ItemText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.65em;
    width: 40%;
  }

  display: flex;
  align-self: center;
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.text_heading};
  margin: 0 0 0 0.5em;
  word-wrap: break-word;
`;

const SupplierContractCard = ({
  data,
  last,
  unconfirmedList,
  handleReportUnrecognized,
  handleConfirmContract,
  loading,
}) => {
  const {
    codContract,
    personName,
    personEmail,
    personPhone,
    segment,
    eventDate,
    linkSegment,
    supplierConfirmed,
  } = data;
  const [contractValue, setContractValue] = React.useState(null);

  React.useEffect(() => {
    const { value } = data;
    if (!contractValue) {
      setContractValue(value);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ItemContainer key={codContract} last={last}>
      <CardTitle>
        <h3>Evento: {
          eventDate === dateUndefined
          ? 'Data indefinida'
          : moment(eventDate).format('DD/MM/YYYY')}</h3>
        <div>
          <p>{personName}</p>
          <p>{personPhone}</p>
          <p>{personEmail}</p>
        </div>
      </CardTitle>
      <DataContainer>
        <SegmentContainer key={segment}>
          <StyledIcon src={linkSegment} />
          <ItemText>{segment}</ItemText>
        </SegmentContainer>
        <InputWrapper>
          <MaterialInput
            style={{ marginBottom: '23px', marginLeft: '5px' }}
            disabled={supplierConfirmed}
            onChange={e => setContractValue(e.target.value)}
            value={contractValue}
            required
            label="Contratado"
            InputProps={{
              inputComponent: MoneyMask,
            }}
          />
        </InputWrapper>
      </DataContainer>
      {unconfirmedList && (
        <ButtonContainer>
          <StyledButton
            click={() => handleReportUnrecognized(codContract, contractValue)}
            text="NÃO RECONHECIDO"
            disabled={loading}
          />
          <StyledButton
            click={() => handleConfirmContract(codContract, contractValue)}
            text="CONFIRMAR"
            disabled={supplierConfirmed || loading}
          />
        </ButtonContainer>
      )}
    </ItemContainer>
  );
};

export default SupplierContractCard;

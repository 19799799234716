import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { medias } from '../utils/mediaQueries';
import theme from '../styles/theme';
import { Tooltip } from './';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-left: ${props =>
    props.border ? `1px solid ${theme.colors.grey1}` : ''};
  border-right: ${props =>
    props.border ? `1px solid ${theme.colors.grey1}` : ''};
  /* ${({ border }) =>
    border &&
    `
    border-left: 1px solid ${theme.colors.grey1};
    border-right: 1px solid ${theme.colors.grey1};
  `}; */
  height: 90%;
  width: 100%;
  padding: 1em 0 2em 0;
`;

const PhaseLabel = styled.span`
  @media ${medias.phoneMax} {
    font-size: 10px;
  }

  display: flex;
  align-items: center;
  font-size: ${theme.fontSize}px;
  color: ${theme.colors.text_heading};
  text-align: center;
  font-weight: bold;
  margin-bottom: 0.5em;

  div {
    margin-left: 10px;
  }
`;

const ClosedLabel = styled.span`
  font-size: ${theme.fontSizeXlg}px;
  text-align: center;
  color: ${theme.colors.primary};
  font-weight: bold;
`;

const RemainingLabel = styled.span`
  font-size: ${theme.fontSizeMed}px;
  text-align: center;
  color: ${theme.colors.grey3};
  font-weight: 300;
`;

export const ClosedSegmentsData = ({
  title,
  closedSegments,
  totalSegments,
  border,
  tooltipText,
}) => {
  return (
    <Wrapper border={border}>
      <PhaseLabel>
        {title} <Tooltip title={tooltipText} />
      </PhaseLabel>
      <ClosedLabel>{closedSegments}</ClosedLabel>
      <RemainingLabel>de {totalSegments}</RemainingLabel>
    </Wrapper>
  );
};

ClosedSegmentsData.propTypes = {
  title: PropTypes.string.isRequired,
  closedSegments: PropTypes.number.isRequired,
  totalSegments: PropTypes.number.isRequired,
  tooltiptText: PropTypes.string.isRequired,
};

export default ClosedSegmentsData;


import React, { useState } from 'react';
import styled from 'styled-components';

import { Tabs } from './';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import Arabesco from '../images/arabescos.svg';
import { hexToRgb } from '../utils/hexToRgb';
import { CirclePlusIcon, CircleMinusIcon } from './icons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
`;

const AssetContainer = styled.div`
  @media ${medias.tabletMin} {
    background-size: contain;
  }

  background-image: url(${Arabesco});
  background-size: cover;
  background-repeat-x: repeat;
  width: 100%;
  height: 2em;
  ${({ bottom }) =>
    bottom
      ? 'margin-top: 1em; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; '
      : 'margin-bottom: 1em;'}
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  background-color: ${theme.colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 0 0 20px;

  cursor: pointer;
`;

const Line = styled.div`
  background-color: ${theme.colors.grey1};
  height: 1px;
  width: 100%;
  margin: 20px 0 20px 0;
`;

const ItemTitle = styled.h4`
  @media ${medias.phoneMax} {
    font-size: 0.8em;
  }

  font-size: ${theme.fontSize * 1.06}px;
  font-weight: bold;
  color: ${theme.colors.text_heading};
  margin: 0;
`;

const ItemText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.65em;
  }

  display: flex;
  align-self: center;
  width: 83%;
  font-size: ${theme.fontSizeLg * 0.9}px;
  color: ${theme.colors.grey3};
  margin: 20px 0 20px 0;
  overflow: hidden;
`;

const tabContent = [
  {
    id: 0,
    title: 'NOIVOS',
  },
  {
    id: 1,
    title: 'FORNECEDORES',
  },
  {
    id: 2,
    title: 'FRANQUIAS',
  },
];

//@TODO Slide-up and slide-down transitions
const FAQCard = ({ questions }) => {
  const [openedQuestion, setOpenedQuestion] = useState(0);
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = id => {
    setCurrentTab(id);
    setOpenedQuestion(0);
  };

  return (
    <Wrapper>
      <Tabs content={tabContent} onClick={id => handleTabChange(id)} />
      <Card>
        <AssetContainer />
        {questions
          ? questions[currentTab].map(({ codFaq, pergunta, resposta }) => (
              <Item key={codFaq}>
                <TitleContainer
                  onClick={() =>
                    setOpenedQuestion(openedQuestion === codFaq ? 0 : codFaq)
                  }
                >
                  {codFaq === openedQuestion ? (
                    <CircleMinusIcon />
                  ) : (
                    <CirclePlusIcon />
                  )}
                  <ItemTitle>{pergunta}</ItemTitle>
                </TitleContainer>
                {codFaq === openedQuestion && (
                  <ItemText aria-hidden={codFaq === openedQuestion}>
                    {resposta}
                  </ItemText>
                )}
                <Line />
              </Item>
            ))
          : null}
        <AssetContainer bottom />
      </Card>
    </Wrapper>
  );
};

export default FAQCard;

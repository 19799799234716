import React from 'react';
import styled from 'styled-components';

import Arabesco from '../images/arabescos.svg';
import theme from '../styles/theme';
import { hexToRgb } from '../utils/hexToRgb';
import { medias } from '../utils/mediaQueries';

export const ArabescoStyles = `
  padding-top: 3em;
  padding-bottom: 3em;
  background-color: white;
  ::after, ::before {
    content: '';
    position: absolute;
    background-image: url(${Arabesco});
    background-repeat: repeat-x;
    width: 100%;
    height: 2em;
    left: 0;
  }
  ::before {
    top: 0;
    border-radius: 20px 20px 0 0;
  }
  ::after {
    bottom: 0;
    border-radius: 0 0 20px 20px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 6rem;
  border: 1px solid ${theme.colors.grey1};
  border-radius: 20px;
  background-color: ${theme.colors.white};
  box-shadow: 0px 4px 8px 0 ${hexToRgb(theme.colors.grey2, 0.5)};
  padding-left: 0.5em;
  padding-right: 0.5em;
  ${ArabescoStyles};
  @media ${medias.tabletMin} {
    padding-left: 2em;
    padding-right: 2em;
  }
`;

const CardContainer = ({ children }) => <Wrapper>{children}</Wrapper>;

export default CardContainer;

import React, { useState } from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';

import { MaterialInput } from '../components';
import { StyledButton } from '../components/styled';
import { useFormState } from 'react-use-form-state';

import { useStateValue } from '../state';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import { bride } from '../services/api';
import theme from '../styles/theme';
import { toast } from 'react-toastify';
import { medias } from '../utils/mediaQueries';
import Arabesco from '../images/arabescos.svg';
import { hexToRgb } from '../utils/hexToRgb';
import { REGEX_EMAIL } from '../utils/regex';
import { userData } from '../services/api';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin: 0 0 50px 0;
  width: 90%;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  background-color: ${theme.colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const AssetContainer = styled.div`
  @media ${medias.tabletMin} {
    background-size: contain;
  }

  background-image: url(${Arabesco});
  background-size: cover;
  background-repeat-x: repeat;
  width: 100%;
  height: 2em;
  ${({ bottom }) =>
    bottom &&
    'margin-top: 1em; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;'}
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  width: 90%;
`;

const StyledHalfInput = styled(InputMask)`
  width: 45%;
`;

const FormTitle = styled.h3`
  font-size: ${theme.fontSizeMed}px;
  color: ${theme.colors.text_heading};
  font-weight: bold;
  margin: 2em;
`;

const BrideProfileForm = () => {
  const [differentPassword, setDifferentPassword] = useState(false);
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [changePasswordError, setChangePasswordError] = useState(false);
  const [initialState, setInitialState] = useState(null);

  const [
    {
      forms: { profileForm },
    },
    dispatch,
  ] = useStateValue();

  useFetch({
    url: urls.bridesContent.getUserInfo,
    method: method.GET,
    condition: () => !initialState,
    callback: data => {
      setInitialState(data);
      if (data) {
        dispatch({
          type: 'setProfileDataForm',
          newForm: data,
        });

        Object.keys(data).forEach(key => formState.setField(key, data[key]));
      }
    },
  });

  const [formState, { text, password }] = useFormState(null, {
    withIds: true,
    onChange(e) {
      const { name, value } = e.target;
      dispatch({
        type: 'changeProfileForm',
        field: name,
        newText: value,
      });
    },
  });

  const updateProfileData = async () => {
    if (!profileForm.email) {
      toast.error('Email é obrigatório!');
      return false;
    }

    const { name, email, phone, cellphone, instagram } = profileForm;
    const payload = {
      name,
      email,
      phone,
      cellphone,
      instagram,
    };

    const response = await bride.updateUserInfo(payload);
    response.ok && response.data
      ? toast.success('Dados alterado com sucesso!', {
          onClose: () => window.location.reload(),
        })
      : toast.error('Não foi possivel alterar os dados.');
  };

  const UpdateProfilePassword = async () => {
    setChangePasswordSuccess(false);
    const {
      newPassword,
      confirmNewPassword,
      currentPassword,
    } = formState.values;

    if (newPassword === confirmNewPassword) {
      const response = await userData.changePassword({
        newPassword,
        currentPassword,
      });

      if (!response.ok) {
        const invalidPassword = response.code === 401;

        setInvalidPassword(invalidPassword);
        setChangePasswordError(!invalidPassword);
      } else {
        setInvalidPassword(false);
        setChangePasswordError(false);
        setChangePasswordSuccess(true);
      }
    }

    setDifferentPassword(newPassword !== confirmNewPassword);
  };

  return (
    <Wrapper>
      <Card>
        <AssetContainer />
        <MaterialInput
          style={{ width: '90%' }}
          label="Nome"
          {...text('name')}
        />
        <MaterialInput
          style={{ width: '90%' }}
          label="Seu melhor e-mail"
          {...text({
            name: 'email',
            validate: value => REGEX_EMAIL.test(value),
          })}
        />
        <MaterialInput
          style={{ width: '90%' }}
          label="Instagram"
          {...text('instagram')}
        />
        <RowContainer>
          <StyledHalfInput
            label="Telefone fixo"
            mask="(99) 9999-9999"
            {...text('phone')}
          >
            {inputProps => <MaterialInput {...inputProps} />}
          </StyledHalfInput>
          <StyledHalfInput
            label="Celular"
            mask="(99) 99999-9999"
            {...text('cellphone')}
          >
            {inputProps => <MaterialInput {...inputProps} />}
          </StyledHalfInput>
        </RowContainer>
        <StyledButton text="ALTERAR" click={updateProfileData} />
        <AssetContainer bottom />
      </Card>
      <FormTitle>Alterar senha</FormTitle>
      <Card>
        <AssetContainer />
        <RowContainer>
          <MaterialInput
            style={{ width: '30%' }}
            label="Senha atual"
            {...password('currentPassword')}
          />
          <MaterialInput
            style={{ width: '30%' }}
            label="Nova senha"
            {...password('newPassword')}
          />
          {/* TODO tamanho da letra quando no mobile esta quebrando */}
          <MaterialInput
            style={{ width: '30%' }}
            label="Confirmar senha"
            {...password('confirmNewPassword')}
          />
        </RowContainer>
        {differentPassword && <p>As senhas estão diferentes</p>}
        {invalidPassword && <p>Senha incorreta</p>}
        {changePasswordError && <p>Erro ao alterar a senha</p>}
        {changePasswordSuccess && <p>Senha alterada</p>}
        <StyledButton text="ALTERAR" click={UpdateProfilePassword} />
        <AssetContainer bottom />
      </Card>
    </Wrapper>
  );
};

export default BrideProfileForm;

import { navigate } from 'gatsby';
import { userData } from "./api";
export const isBrowser = () => typeof window !== 'undefined';

const getOpts = () => ({
  localUserId: isBrowser() && localStorage.getItem('userId'),
  localToken: isBrowser() && localStorage.getItem('token'),
  localUserRule: isBrowser() && localStorage.getItem('rule'),
  localUserCategory: isBrowser() && localStorage.getItem('supplierCategory'),
  localIsSupplier:
    isBrowser() &&
    localStorage.getItem('isSupplier') === 'true',
  localIsBride:
    isBrowser() &&
    localStorage.getItem('isBride') === 'true',
  localUserName:
    isBrowser() &&
    localStorage.getItem('userName'),
  localFirstLogin:
    isBrowser() &&
    localStorage.getItem('firstLogin'),
  localSiteSelectedRegion:
    isBrowser() &&
    sessionStorage.getItem('siteSelectedRegion'),
})

export default (dispatch, inApp) => {
  const {
    localUserId,
    localToken,
    localIsSupplier,
    localIsBride,
    localUserName,
    localUserRule,
    localUserCategory
  } = getOpts();

  if (
    localUserId &&
    localToken &&
    (localIsBride || localIsSupplier) &&
    localUserName && localUserRule && localUserCategory
  ) {
    dispatch({
      type: 'registerSession',
      userId: localUserId,
      token: localToken,
      isSupplier: localIsSupplier,
      isBride: localIsBride,
      userName: localUserName,
      rule: localUserRule,
      supplierCategory: localUserCategory
    });
    registerAccess(localUserId, localIsBride, localIsSupplier);
    if (!inApp) {
      console.log('Redirect to /app');
      navigate('/app');
    }
  }
};

export const checkSiteSelectedRegion = (dispatch) => {
  const { localSiteSelectedRegion } = getOpts();

  if (localSiteSelectedRegion) {
    dispatch({
      type: 'updateSiteSelectedRegion',
      siteSelectedRegion: localSiteSelectedRegion
    });
  }
};

export const getAppRedirect = () => {
  const { localIsBride, localFirstLogin, localIsSupplier } = getOpts();

  if (localIsBride) {
    console.log(
      `Redirecting to /app/noivos${localFirstLogin === 1 ? '' : '/inicio'}`
    );
    return `/app/noivos${localFirstLogin === 1 ? '' : '/inicio'}`;
  } else if(localIsSupplier) {
    console.log('Redirecting to /app/fornecedor');
    return '/app/fornecedor';
  } else {
    console.log('Redirecting to /');
    return '/';
  }
};

export const registerAccess = async (localUserId, localIsBride, localIsSupplier) => {
  if (localIsBride) {
    await userData.registerAccess({
     localUserId,
     type: 'bride'
    });
  } else if (localIsSupplier) {
    await userData.registerAccess({
      localUserId,
      type: 'supplier'
     });
    }
};

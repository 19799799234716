import React from 'react';
import PropTypes from 'prop-types';

import {
  HeaderLogo,
  HeaderWrapper,
  HeaderNav,
  HeaderProfileImage,
  IconLink,
  TopHeaderContainer,
  UserInfo,
} from './HeaderComponents';
import {
  ExitIcon,
  HomeIcon,
  BenefitsIcon,
  RewardIcon,
  IndicateIcon,
  RingsIcon,
  UserIcon,
} from '../icons';
import HamburgerButton from '../HamburgerButton';
import { Backdrop } from '../styled';
import {
  DiamondHeaderIcon,
  ContractIcon,
  TrophyIcon,
  CalendarIcon,
  CalculatorIcon,
} from '../icons/SupplierIcons';
import theme from '../../styles/theme';
import HeaderLinks from './HeaderLinks';

const SupplierHeader = ({ session, logOut }) => {
  const [menuOpen, setMenu] = React.useState(false);
  const { userName, vbs, supplierName, supplierCategory } = session;

  const supplierMenuItems = [
    {
      label: 'Home',
      path: '/app/fornecedor',
      icon: HomeIcon,
      included: true,
      active: true,
    },
    {
      label: 'Benefícios',
      path: '/app/fornecedor/vantagens',
      icon: BenefitsIcon,
      active: true,
      included: true,
      visible: Number(supplierCategory) !== 4
    },
    {
      label: 'Inserir Noivos',
      path: '/app/fornecedor/convidar',
      icon: IndicateIcon,
      included: true,
    },
    {
      label: 'Orçamentos',
      path: '/app/fornecedor/oportunidades',
      icon: CalculatorIcon,
      active: true,
      included: true,
      visible: Number(supplierCategory) !== 4
    },
    {
      label: 'Noivos',
      path: '/app/fornecedor/noivos',
      icon: RingsIcon,
      included: true,
      visible: Number(supplierCategory) !== 4
    },
    {
      label: 'Contratos',
      path: '/app/fornecedor/contratos',
      icon: ContractIcon,
      active: true,
      included: true,
      visible: Number(supplierCategory) !== 4
    },
    {
      label: 'Agenda',
      path: '/app/fornecedor/agenda',
      icon: CalendarIcon,
      included: true,
      visible: Number(supplierCategory) !== 4
    },
    {
      label: 'Prêmios',
      path: '/app/fornecedor/premios',
      icon: RewardIcon,
      active: true,
      included: true,
      visible: Number(supplierCategory) !== 4
    },
    {
      label: 'Recompensas',
      path: '/app/fornecedor/recompensas',
      icon: TrophyIcon,
      included: true,
      visible: Number(supplierCategory) !== 4
    },
    {
      label: 'Perfil',
      path: '/app/fornecedor/perfil',
      icon: UserIcon,
      included: true,
    },
  ];

  const toggleMenu = () => {
    if (menuOpen) {
      document.documentElement.classList.remove('html-modal_open');
    } else {
      document.documentElement.classList.add('html_modal-open');
    }
    setMenu(!menuOpen);
  };

  const onExitModal = () => {
    document.documentElement.classList.remove('html-modal_open');
    setMenu(false)
  }

  return (
    <>
      {menuOpen && <Backdrop aria-hidden={true} onClick={toggleMenu} />}
      <HeaderWrapper menuOpen={menuOpen}>
        <TopHeaderContainer>
          <HeaderLogo path="/app/fornecedor" />
          <HamburgerButton
            onClick={toggleMenu}
            aria-label={`Botão para ${menuOpen ? 'fechar' : 'abrir'} o menu`}
            isOpen={menuOpen}
          />
          <HeaderNav aria-label={`Menu ${menuOpen ? 'aberto' : 'fechado'}`}>
            <HeaderProfileImage icon={ExitIcon} />
            <UserInfo>
              <div>
                <span className="title" aria-label="Nome da empresa">
                  {supplierName}
                </span>
              </div>
              <div>
                Usuário:
                <span> {userName}</span>
              </div>
            </UserInfo>
            <UserInfo>
              <DiamondHeaderIcon /> Moedas:{' '}
              <span style={{ color: theme.colors.primary }}>{vbs}</span>
            </UserInfo>
            <IconLink icon={ExitIcon}>
              <button onClick={logOut}>Sair</button>
            </IconLink>
          </HeaderNav>
        </TopHeaderContainer>
        <HeaderLinks onExit={onExitModal} items={supplierMenuItems} />
      </HeaderWrapper>
    </>
  );
};

SupplierHeader.propTypes = {
  logOut: PropTypes.func.isRequired,
  session: PropTypes.shape({
    userName: PropTypes.string.isRequired,
    supplierName: PropTypes.string,
    vbs: PropTypes.number,
  }),
};

export default SupplierHeader;

import React from 'react';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import { toast } from 'react-toastify';

import { useStateValue } from '../state';
import {
  MaterialInput,
  MaterialSelect,
  MaterialInputLabel,
  MaterialMenuItem,
} from '../components';
import { medias } from '../utils/mediaQueries';
import Arabesco from '../images/arabescos.svg';
import theme from '../styles/theme';
import { hexToRgb } from '../utils/hexToRgb';
import { REGEX_EMAIL } from '../utils/regex';
import { StyledButton } from './styled';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import { siteContent } from '../services/api';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin: 0 0 50px 0;
  width: 90%;
`;

const RowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const AssetContainer = styled.div`
  @media ${medias.tabletMin} {
    background-size: contain;
  }

  background-image: url(${Arabesco});
  background-size: cover;
  background-repeat-x: repeat;
  width: 100%;
  height: 2em;
  ${({ bottom }) =>
    bottom
      ? 'margin-top: 1em; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;'
      : 'margin-bottom: 0.5em; border-top-left-radius: 10px; border-top-right-radius: 10px;'}
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  background-color: ${theme.colors.white};
  border-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
`;

const StyledFormControl = styled(props => <FormControl {...props} />)`
  width: ${({ half }) => (half ? '45%' : '100%')};
`;

const ContactForm = () => {
  const [
    {
      forms: { contactForm },
      contactUs: { regions, protocolTypes, applicantTypes },
    },
    dispatch,
  ] = useStateValue();

  useFetch({
    url: urls.siteContent.regions,
    method: method.GET,
    condition: () => !regions,
    callback: data =>
      dispatch({
        type: 'setRegions',
        newRegions: data,
      }),
  });

  useFetch({
    url: urls.siteContent.protocolTypes,
    method: method.GET,
    condition: () => !protocolTypes,
    callback: data =>
      dispatch({
        type: 'setProtocolTypes',
        newProtocolTypes: data,
      }),
  });

  useFetch({
    url: urls.siteContent.applicantTypes,
    method: method.GET,
    condition: () => !applicantTypes,
    callback: data =>
      dispatch({
        type: 'setApplicantTypes',
        newApplicantTypes: data,
      }),
  });

  const contactSucess = () => {
    toast.success('Sua mensagem foi enviada!')
    dispatch({
      type: 'clearContactForm',
    });
  }

  const SendContactUsForm = async () => {
    const { subject, whoIs, name, email, message } = contactForm;

    const body = {
      subject,
      whoIs,
      name,
      email,
      message,
    };

    const response = await siteContent.contactUsForm(body);
    response.ok && response.data
      ? contactSucess()
      : toast.error('Não foi possível cadastrar!');
  };

  const { subject, whoIs, name, email, message } = contactForm;

  const handleChange = (newText, field) => {
    dispatch({
      type: 'changeContactForm',
      newText,
      field,
    });
  };

  return (
    <Wrapper>
      <Card>
        <AssetContainer />
        <InputContainer>
          <RowContainer>
            <StyledFormControl half>
              <MaterialInputLabel htmlFor="whoIs-simple">
                Eu sou
              </MaterialInputLabel>
              <MaterialSelect
                onChange={event => handleChange(event.target.value, 'whoIs')}
                value={whoIs}
                inputProps={{
                  name: 'whoIs',
                  id: 'whoIs-simple',
                }}
              >
                {applicantTypes
                  ? applicantTypes.map(
                      ({ TipoSolicitante, codTipoSolicitante }) => (
                        <MaterialMenuItem
                          key={codTipoSolicitante}
                          value={TipoSolicitante}
                        >
                          {TipoSolicitante}
                        </MaterialMenuItem>
                      )
                    )
                  : null}
              </MaterialSelect>
            </StyledFormControl>
            <StyledFormControl half>
              <MaterialInputLabel htmlFor="subject-simple">
                Assunto
              </MaterialInputLabel>
              <MaterialSelect
                onChange={event => handleChange(event.target.value, 'subject')}
                value={subject}
                inputProps={{
                  name: 'subject',
                  id: 'subject-simple',
                }}
              >
                {protocolTypes
                  ? protocolTypes.map(({ TipoProtocolo, codTipoProtocolo }) => (
                      <MaterialMenuItem
                        key={codTipoProtocolo}
                        value={TipoProtocolo}
                      >
                        {TipoProtocolo}
                      </MaterialMenuItem>
                    ))
                  : null}
              </MaterialSelect>
            </StyledFormControl>
          </RowContainer>
          <MaterialInput
            style={{ width: '100%' }}
            label="Seu nome"
            value={name}
            onChange={event => handleChange(event.target.value, 'name')}
          />
          <MaterialInput
            style={{ width: '100%' }}
            label="Seu melhor email"
            value={email}
            onChange={event => handleChange(event.target.value, 'email')}
          />
          <MaterialInput
            label="Sua mensagem"
            style={{ width: '100%' }}
            multiline
            rows="6"
            value={message}
            onChange={event => handleChange(event.target.value, 'message')}
          />
          <StyledButton
            text="ENVIAR"
            click={() => {
              if (!subject || !name || !email || !message) {
                toast.error('Todos os campos são obrigatórios!');
                return false;
              }

              if (!REGEX_EMAIL.test(email)) {
                toast.error('Digite um e-mail válido!');
                return false;
              }

              return SendContactUsForm();
            }}
          />
        </InputContainer>
        <AssetContainer bottom />
      </Card>
    </Wrapper>
  );
};

export default ContactForm;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  DropdownRegions,
  DropdownSegments,
  DropdownFavorites,
  OrderBy,
  SegmentsContainer,
} from './';
import theme from '../styles/theme';
import { medias } from '../utils/mediaQueries';
import { SearchIcon, FilterIcon } from './icons';
import Grid from '@material-ui/core/Grid';
import { useStateValue } from '../state';

const StyledGrid = styled(Grid)`
  height: auto;
  background-color: ${theme.colors.secondary};
  margin: initial;
  width: 100%;
`;

const StyledRegionContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-content: center;
  justify-content: ${({ filter }) => (filter ? 'flex-start' : 'flex-end')};
  padding-top: 2px;
`;

const StyledLine = styled.div`
  height: 2px;
  background-color: ${theme.colors.white};
`;

const StyledVerticalLine = styled.div`
  width: 1px;
  height: 30px;
  background-color: ${theme.colors.white};
  margin: 5px 0 3px 0;
`;

const FilterText = styled.p`
  @media ${medias.phoneMin} {
    margin: 0 68% 0 0;
  }

  @media ${medias.smallerTabletMin} {
    margin: 0 34% 0 0;
  }

  @media ${medias.tabletMin} {
    margin: 0 58% 0 0;
  }

  font-family: Lato;
  font-size: ${theme.fontSize}px;
  font-weight: bold;
  line-height: 2.38;
  letter-spacing: -0.4px;
  text-align: left;
  margin: 0 50% 0 0;
  color: ${theme.colors.white};

  &:hover {
    cursor: pointer;
  }
`;

const StyledInput = styled.input`
  background-color: transparent;
  border: none;
  font-family: Lato;
  font-size: ${theme.fontSize}px;
  font-weight: bold;
  line-height: 2.38;
  letter-spacing: -0.4px;
  text-align: left;
  color: ${theme.colors.white};
  padding-left: 5px;

  @media ${medias.smallerTabletMax} {
    width: ${props => (props.isPublicSite ? '87%' : '100%')};
  }

  @media ${medias.phoneMax} {
    width: ${props => (props.isPublicSite ? '82%' : '100%')};
  }
  width: ${props => (props.isPublicSite ? '91%' : '100%')};
`;

const StyledIcon = styled(SearchIcon)`
  margin: 10px 15px 0 10px;

  width: 20px;
  height: 20px;

  &:hover {
    cursor: pointer;
  }
`;

const StyledFilterIcon = styled(FilterIcon)`
  margin-top: 10px;

  &:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  width: 100%;
`;

const SearchBar = ({
  className,
  filter,
  advantages,
  onClickSearch,
  onClickFilter,
  placeholder,
  searchFromHome = '',
  droppedFromHome = '',
  handleChangeOrder,
  favorites,
  isHomePage = false,
  segmentsDropdown = false,
}) => {
  const [searchString, setSearchString] = useState(searchFromHome);
  const [searchValue, setSearchValue] = useState(droppedFromHome);
  const [showSegmentsContainer, setShowSegmentsContainer] = useState(false);
  const [filteredSegments, setFilteredSegments] = useState([]);
  const [isTyping, setIsTyping] = useState(false);

  const [
    {
      segments: { segments },
      session: { userId },
    },
  ] = useStateValue();

  const handleKeyPress = key => {
    if (key === 'Enter') {
      return filter
        ? onClickSearch(searchString)
        : onClickSearch(searchString, searchValue);
    }
  };

  useEffect(() => {
    if (segments) {
      const orderedSegments = segments.sort((a, b) => a.Segmento - b.Segmento);
      setFilteredSegments(orderedSegments);
    }
  }, [segments]);

  const handleInputChange = value => {
    setSearchString(value);

    if (segments) {
      const _filteredSegments = segments.filter(
        item => item.Segmento.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      setFilteredSegments(_filteredSegments);
      if (filteredSegments.length) {
        setShowSegmentsContainer(true);
      } else {
        setShowSegmentsContainer(false);
      }
    } 
  };

  const handleIsTyping = value => {
    if (value) {
      setIsTyping(true);
    } else {
      setIsTyping(false);
    }
  };

  const encodeCharacter = string => {
    if (string) {
      return string.replace('&', '%26');
    }
    return string;
  };

  return (
    <Wrapper>
      <StyledGrid container spacing={32} filter={filter} className={className}>
        {handleChangeOrder && (
          <Grid item xs={12} sm={2} md={2}>
            <OrderBy onChangeOrder={handleChangeOrder} />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={handleChangeOrder ? 5 : userId ? 7 : 12}
          md={handleChangeOrder ? 5 : userId ? 7 : 12}
        >
          {!favorites && (
            <>
              <StyledInput
                value={searchString}
                type="text"
                placeholder={placeholder}
                onChange={event => handleInputChange(event.target.value)}
                onFocus={() => setShowSegmentsContainer(true)}
                onBlur={() => setShowSegmentsContainer(false)}
                onKeyUp={event => handleIsTyping(event.target.value)}
                onKeyPress={e => handleKeyPress(e.key)}
                isPublicSite={userId ? false : true}
              />
              {!userId && (
                <StyledIcon
                  onClick={() =>
                    filter
                      ? onClickSearch(encodeCharacter(searchString))
                      : onClickSearch(
                          encodeCharacter(searchString),
                          searchValue
                        )
                  }
                />
              )}
              <StyledLine />
            </>
          )}
        </Grid>

        {userId && (
          <Grid item xs={handleChangeOrder ? 12 : 5} sm={5} md={5}>
            <StyledRegionContainer filter={filter}>
              {filter && (
                <>
                  <StyledFilterIcon onClick={onClickFilter} />
                  <FilterText onClick={onClickFilter}> Filtrar</FilterText>
                </>
              )}
              {advantages && (
                <DropdownSegments
                  value={searchValue.Segmento}
                  onSelectItem={setSearchValue}
                  placeholder={'Segmento'}
                />
              )}
              {favorites && (
                <DropdownFavorites
                  value={searchValue.value}
                  onSelectItem={setSearchValue}
                  placeholder={placeholder}
                />
              )}
              {!filter && !advantages && !favorites && (
                <DropdownRegions
                  value={searchValue}
                  onSelectItem={setSearchValue}
                  placeholder={'Região'}
                />
              )}

              <StyledVerticalLine />

              <StyledIcon
                onClick={() =>
                  filter
                    ? onClickSearch(encodeCharacter(searchString))
                    : onClickSearch(encodeCharacter(searchString), searchValue)
                }
              />
            </StyledRegionContainer>
            <StyledLine />
          </Grid>
        )}
      </StyledGrid>
      {segmentsDropdown &&
        filteredSegments.length > 0 &&
        showSegmentsContainer && (
          <SegmentsContainer
            segments={filteredSegments}
            onClick={segment => setSearchString(segment)}
            isHomePage={isHomePage}
            isTyping={isTyping}
          />
        )}
    </Wrapper>
  );
};

SearchBar.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.bool,
};

export default SearchBar;

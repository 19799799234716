import produce from 'immer';
import { setToken } from '../../hooks/useFetch';
import { updateToken } from '../../services/api';

export default (session, action) => {
  const { type } = action;
  // ----> Logs
  // console.log('Called session reducer');
  // console.log(`Called action type ${type}`);
  // console.log('Prev state:');
  // console.table(session);
  // console.log('Action payload:');
  // console.table(action);

  // Preparando o payload do updateSession:
  // Únicas propriedades que mudamos são eventType -> category
  // e points -> balance, por isso recebem tratamento especial.
  // Aproveitamos e removemos type do payload também

  // Catch any inconsistent payload
  if (typeof action !== 'object' || typeof type !== 'string') {
    return session;
  }

  switch (type) {
    case 'registerSession':
      setToken(action.token);
      updateToken(action.token);
      return produce(session, draft => {
        draft.userId = action.userId;
        draft.token = action.token;
        draft.userName = action.userName;
        draft.isSupplier = action.isSupplier || false;
        draft.isBride = action.isBride || false;
        draft.rule = action.rule || null;
        draft.supplierCategory = action.supplierCategory || null;
      });

    case 'logout':
      setToken(undefined);
      updateToken(undefined);
      localStorage.clear();

      return produce(session, draft => {
        draft.userId = null;
        draft.token = null;
        draft.isSupplier = null;
        draft.isBride = null;
        draft.selectedRegion = null;
        draft.selectedEvent = null;
      });

    case 'changeSessionForm':
      return produce(session, draft => {
        draft[action.field] = action.newText;
      });

    case 'clearEventAndRegion':
      return produce(session, draft => {
        draft.selectedRegion = null;
        draft.selectedEvent = null;
      });

    // Como não sabemos o formato da action, usamos um spread em todas as propriedades
    case 'updateSessionForm':
      return produce(session, draft => {
        draft.date = action.date;
        draft.eventFinished = action.eventFinished;
        draft.eventInactive = action.eventInactive;
        draft.category = action.eventType;
        draft.balance = action.points;
        draft.selectedEvent = action.selectedEvent;
        draft.selectedRegion = action.selectedRegion;
        draft.stage = action.stage;

        draft.vbs = action.vbs;
        draft.supplierName = action.supplierName;
        draft.potential = action.potential;
        draft.contractsCount = action.contractsCount;
        draft.totalContractsValue = action.totalContractsValue;
        draft.roi = action.roi;
        draft.pointsBalance = action.pointsBalance;
        draft.supplierContracts = action.supplierContracts;
        draft.currentSupplierContract = action.currentSupplierContract;
      });

    case 'updateEventData':
      return produce(session, draft => {
        draft.date = action.date;
        draft.eventFinished = action.eventFinished;
        draft.eventInactive = action.eventInactive;
        draft.category = action.eventType;
        draft.balance = action.points;
      });

    case 'updateSiteSelectedRegion':
        return produce(session, draft => {
          draft.siteSelectedRegion = action.siteSelectedRegion;
        });

    default:
      return session;
  }
};

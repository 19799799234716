import React from 'react';
import TextField from '@material-ui/core/TextField';

import { theme } from '../styles/theme';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const StyledTextField = styled(props => <TextField {...props} />)`
  width: 100%;

  @media (max-width: 756px){
    .MaterialInput-cssLabel-1 {
      font-size: 0.9em !important;
    }
  }
`;

const styles = media => ({
  cssLabel: {
    [media.breakpoints.down('lg')]: {
      fontSize: '0.8em',
      marginBottom:'10px'
    },
    fontSize: theme.fontSizeSm,
    color: theme.colors.grey3,
    '&$cssFocused': {
      color: theme.colors.grey3,
    },
  },
  cssInput: {
    fontSize: theme.fontSizeSm,
    color: theme.colors.text_heading,
    paddingTop:'10px'
  },
  cssFocused: {},
  cssControl: {
    margin: 0,
  },
  cssUnderline: {
    '&:after': {
      borderBottomColor: theme.colors.grey3,
    },
  },
});

const MaterialInput = props => {
  const { classes, InputProps, ...other } = props;

  return (
    <StyledTextField
      {...other}
      margin="dense"
      InputProps={{
        classes: {
          root: classes.cssInput,
          focused: classes.cssFocused,
          underline: classes.cssUnderline,
        },
        ...InputProps
      }}
      InputLabelProps={{
        classes: {
          root: classes.cssLabel,
          focused: classes.cssFocused,
        },
        shrink: true
      }}
    />
  );
};

// All material textfield props
MaterialInput.propTypes = {};

export default withStyles(styles)(MaterialInput);

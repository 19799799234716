import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Arabesco from '../images/arabescos.svg';
import { medias } from '../utils/mediaQueries';
import { Button, AdvantageBudgetModal, AdvantagesDetailsModal } from './';
import { hexToRgb } from '../utils/hexToRgb';
import { theme } from '../styles/theme';
import { StyledHeart, StyledOutline } from './styled';
import { useStateValue } from '../state';
import { tracking } from '../services/api';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  margin-bottom: 20px;
  width: 100%;
  height: 250px;

  background-color: ${theme.colors.white};
  border-radius: 10px;
  box-shadow: 0px 3px 10px 0px ${hexToRgb(theme.colors.grey2, 0.5)};
`;

const AssetContainer = styled.div`
  background-image: url(${Arabesco});
  background-size: cover;
  width: 100%;
  height: 40px;
  border-radius: 10px;
`;

const HalfButton = styled(props => <Button {...props} />)`
  @media ${medias.phoneMax} {
    height: 40px;
    font-size: 10.6px;
  }
  background-color: ${props =>
    props.selected ? theme.colors.white : theme.colors.text_heading};
  color: ${props =>
    props.selected ? theme.colors.text_heading : theme.colors.white};
  border-color: ${theme.colors.text_heading};
  width: 100%;
  font-size: ${theme.fontSizeSm}px;
  text-align: center;
`;

const ImageContainer = styled.div`
  height: 100%;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  height: 100%;
  object-fit: contain;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  min-width: 30%;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
`;

const SegmentContainer = styled.div`
  @media ${medias.phoneMax} {
    width: 50%;
  }

  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`;

const StyledIcon = styled.img`
  width: 30px;
  height: 30px;
  fill: ${({ color }) => color};
`;

const Title = styled.span`
  @media ${medias.phoneMax} {
    font-size: ${theme.fontSizeSm}px;
  }

  font-size: ${theme.fontSize}px;
  color: ${theme.colors.text_heading};
  font-weight: bold;
  text-align: left;
`;

const SubTitle = styled.span`
  @media ${medias.phoneMax} {
    font-size: ${theme.fontSizeSm}px;
    margin-left: 5px;
  }

  display: flex;
  justify-content: space-between;
  font-size: ${theme.fontSize}px;
  color: ${theme.colors.grey3};
  font-weight: 300;
  text-align: left;
  margin-bottom: 0.5em;
`;

const Savings = styled.span`
  font-size: ${theme.fontSize}px;
  color: ${theme.colors.primary};
  font-weight: bold;
  text-align: left;
  margin: 0 0.5em;
`;

const ItemText = styled.p`
  @media ${medias.phoneMax} {
    font-size: 0.65em;
    width: 40%;
  }

  display: flex;
  align-self: center;
  font-size: ${theme.fontSizeSm}px;
  color: ${theme.colors.text_heading};
  margin: 0 0 0 0.5em;
  word-wrap: break-word;
`;

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 170px;
  padding: 5px 10px;
`;

const HeartsContainer = styled.div`
  display: flex;
`;

const SmallAdvantagesCard = ({
  advantageData,
  category,
  removeFavorite,
  addFavorite,
  isFavoritePage,
}) => {
  const [categoryDiscount, setDiscount] = useState(null);
  const [budgetOpened, setBudgetOpened] = useState(false);
  const [favorited, setFavorited] = useState(advantageData.userFavorited);
  const [categoryForMore, setCategoryForMore] = useState(null);
  const [moreDiscount, setMoreDiscount] = useState(null);
  const [detailsOpened, setDetailsOpened] = useState(false);

  const [
    {
      session: { selectedEvent },
    },
  ] = useStateValue();

  useEffect(() => {
    if (!categoryDiscount) {
      const _discountLight = (discount / 100) * 80;
      const _discountPlus = (discount / 100) * 90;
      if (category === 'LIGHT') {
        setDiscount(_discountLight.toFixed(2));
        setMoreDiscount(_discountPlus - _discountLight);
        setCategoryForMore('PLUS');
      } else if (category === 'PLUS') {
        setDiscount(_discountPlus.toFixed(2));
        setMoreDiscount(discount - _discountPlus);
        setCategoryForMore('VIP');
      } else {
        setDiscount(discount.toFixed(2));
      }
    }
  }, [category, categoryDiscount, discount]);

  const {
    codAdvantage,
    name,
    supplierName,
    discount,
    segment,
    codSegment,
    image,
    linkSegment,
    otherBrideFavorited,
    description,
    resume,
  } = advantageData;

  const renderHeartIcon = () => {
    const Component = favorited ? StyledHeart : StyledOutline;
    const favoriteHandler = favorited
      ? () => removeFavorite(codAdvantage, 'advantages')
      : () => addFavorite(codAdvantage);

    return (
      <Component
        onClick={() => {
          setFavorited(!favorited);
          favoriteHandler();
        }}
      />
    );
  };

  const renderOtherBrideHeart = () =>
    otherBrideFavorited ? (
      <StyledHeart secondary />
    ) : (
      <StyledOutline secondary />
    );

  const trackAdvantage = async () => {
    const payload = {
      eventId: selectedEvent,
      advantageId: codAdvantage,
    };

    await tracking.advantage(payload);
  };

  const handleDetailsClick = () => {
    setDetailsOpened(true);
    trackAdvantage();
  };

  const handleBudgetClick = () => {
    setBudgetOpened(true);
    trackAdvantage();
  };

  return (
    <Wrapper>
      <AssetContainer />
      <MainContainer>
        <ImageContainer>
          {image && <Image src={image} alt="Imagem do benefício" />}
        </ImageContainer>
        <DataContainer>
          <Title>{name}</Title>
          <SubTitle>{supplierName}</SubTitle>
          <SegmentContainer key={codSegment}>
            <StyledIcon src={linkSegment} />
            <ItemText>{segment}</ItemText>
          </SegmentContainer>
          <SubTitle>
            Economize
            <Savings>R$ {categoryDiscount}</Savings>
            <HeartsContainer>
              {renderHeartIcon()}
              {isFavoritePage && renderOtherBrideHeart()}
            </HeartsContainer>
          </SubTitle>
        </DataContainer>
        <ButtonsContainer>
          <HalfButton text="DETALHES" click={handleDetailsClick} />
          <HalfButton text="EU QUERO" click={handleBudgetClick} />
        </ButtonsContainer>
      </MainContainer>
      <AssetContainer />
      <AdvantageBudgetModal
        advantageData={advantageData}
        opened={budgetOpened}
        categoryDiscount={categoryDiscount}
        closeModal={() => setBudgetOpened(false)}
      />
      <AdvantagesDetailsModal
        moreDiscount={moreDiscount}
        categoryForMore={categoryForMore}
        description={description}
        resume={resume}
        opened={detailsOpened}
        closeModal={() => setDetailsOpened(false)}
      />
    </Wrapper>
  );
};

SmallAdvantagesCard.propTypes = {
  advantageData: PropTypes.shape({
    codAdvantage: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    supplierName: PropTypes.string.isRequired,
    discount: PropTypes.number.isRequired,
    segment: PropTypes.string.isRequired,
    codSegment: PropTypes.number.isRequired,
    isFavorited: PropTypes.bool.isRequired,
    image: PropTypes.string.isRequired,
    linkSegment: PropTypes.string.isRequired,
  }),
};

export default SmallAdvantagesCard;

import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';

import { theme } from '../styles/theme';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  cssLabel: {
    fontSize: theme.fontSizeMed,
    color: theme.colors.grey3,
    '&$cssFocused': {
      color: theme.colors.grey3,
      borderColor: theme.colors.grey3,
    },
  },
  cssFocused: {},
};

const MaterialInputLabel = props => {
  const { classes, ...other } = props;

  return (
    <InputLabel
      {...other}
      classes={{
        root: classes.cssLabel,
        focused: classes.cssFocused,
      }}
    />
  );
};

// All material textfield props
MaterialInputLabel.propTypes = {
};

export default withStyles(styles)(MaterialInputLabel);

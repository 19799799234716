export const investmentValue = [
  '40.000',
  '50.000',
  '60.000',
  '70.000',
  '80.000',
  '90.000',
  '100.000',
  '120.000',
  '140.000',
  '160.000',
  '180.000',
  '200.000',
  '220.000',
  '240.000',
  '260.000',
  '280.000',
  '300.000',
  'acima de 300.000',
];

export const invitedNumber = [
  'até 50',
  'de 50 a 99',
  'de 100 a 149',
  'de 150 a 199',
  'de 200 a 249',
  'de 250 a 299',
  'de 300 a 349',
  'acima de 350',
];

export const reasonDontContract = [
  'Localização do Fornecedor',
  'Valor',
  'Fornecedor não possui a data disponível',
  'Gostei mais de outro fornecedor',
  'Desisti de contratar esse segmento',
  'Outro',
];

export const years = [
  2018,
  2019,
  2020,
  2021,
  2022,
  2023,
  2024,
  2025,
  2026,
  2027,
  2028,
  2029,
  2030,
];

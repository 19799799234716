import React from 'react';
import styled from 'styled-components';

import ModalContainer from './ModalContainer';
import { PrioritySegmentList } from './';
import { bride } from '../services/api';
import { useStateValue } from '../state';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NextSegmentsModal = ({ data, closeModal, opened, updateData }) => {
  const [
    {
      session: { selectedEvent },
    },
  ] = useStateValue();

  const handleClickConfirm = async data => {
    const payload = {
      selectedSegments: data,
      event: selectedEvent,
    };

    const response = await bride.updatePrioritySegments(payload);

    if (response.ok) {
      updateData();
      closeModal();
    }
  };

  return (
    <ModalContainer opened={opened} closeModal={closeModal} hasArabesco>
      <Wrapper>
        <PrioritySegmentList data={data} onClickConfirm={handleClickConfirm} />
      </Wrapper>
    </ModalContainer>
  );
};

export default NextSegmentsModal;

module.exports = [{
      plugin: require('/opt/atlassian/pipelines/agent/build/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"productionPrefix":"mat"},
    },{
      plugin: require('/opt/atlassian/pipelines/agent/build/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Lato:300,700"]}},
    },{
      plugin: require('/opt/atlassian/pipelines/agent/build/node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://88e8a755604d442c9a6f286e64a5a23b@sentry.io/1415496","environment":"production"},
    },{
      plugin: require('/opt/atlassian/pipelines/agent/build/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
